import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import moment from "moment/moment";


const isAuth = () => {
    const parse = JSON.parse(localStorage.getItem('auth_user_type'));
    const inCheck = parse?.type;


    return inCheck === 'ADMIN' ||
        inCheck === 'CUSTOMER-SUPPORT' ||
        inCheck === 'SUB-ADMIN' ||
        inCheck === 'MARKETING' ||
        inCheck === 'SALE' ||
        inCheck === 'DEVELOPER';
};

const isSessionExpire = () => {
    let time_stamp = JSON.parse(localStorage.getItem('auth_user'))?.time_stamp;
    time_stamp = parseInt(time_stamp);

    const curr_time_stamp = moment().unix();

    if(!time_stamp || (time_stamp < curr_time_stamp)){
        let i18nextLng = localStorage.getItem('i18nextLng');
        localStorage.clear();
        localStorage.setItem('i18nextLng', i18nextLng);

        return true;
    }

    return false;
}
const ClientProtectedRoute = () => {
    const inCheck = isAuth();

    let isExpire = false;
    if(inCheck) {
        isExpire = isSessionExpire();
    }


    return !isExpire && inCheck? (
        <Outlet />
    ) : (
        <Navigate to="/login" />
    );
};

export { ClientProtectedRoute };
