import React, {useState, useContext, useEffect} from 'react';

import { useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports

import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {enable2FaQR, generate2FaQR, login, verify2FAOTP} from 'api/api';

import { toast } from 'react-toastify';
import ToastContext from 'context/ToastContext';
import moment from "moment";
import {useTranslation} from "react-i18next";
import {SET_FONT_FAMILY} from "../../store/actions";
// ============================|| FIREBASE - LOGIN ||============================ //

const OTPComponent = ({ ...others }) => {
    const toastOptions = useContext(ToastContext);
    let navigate = useNavigate();
    const theme = useTheme();


    const { t, i18n } = useTranslation();



    useEffect(() => {

    }, []);

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">{t('OTP')}</Typography>
                    </Box>
                </Grid>
            </Grid>

            <Formik
                initialValues={{
                    user_id: JSON.parse(localStorage.getItem('auth_user'))?.user?._id,
                    otp: ''
                }}
                validationSchema={Yup.object().shape({
                    otp: Yup.string().max(255).required(t('OTP is required')),
                })}
                onSubmit={(values) => {
                    verify2FAOTP(values)
                        .then((res) => {
                            if(res.data.code === 1){
                                toast.success(i18n.language==='ar'?res.data.ar_message:res.data.en_message, toastOptions);



                                localStorage.setItem(
                                    'auth_user',
                                    JSON.stringify({
                                        user: res.data.user,
                                        time_stamp: moment().add(1, 'days').unix()
                                    })
                                );


                                navigate('/dashboard');


                            }
                            else{
                                toast.error(i18n.language==='ar'?res.data.ar_message:res.data.en_message, toastOptions);
                            }
                        })
                        .catch((err) => {
                            if (err?.response?.status === 401) {
                                toast.error(err.response?.data?.error, toastOptions);
                            }
                        });
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>

                        <FormControl fullWidth error={Boolean(touched.otp && errors.otp)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-login">{t('OTP')}</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-otp-login"
                                type="text"
                                value={values.otp}
                                name="otp"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label={t('Enter OTP')}
                                inputProps={{}}
                            />
                            {touched.otp && errors.otp && (
                                <FormHelperText error id="standard-weight-helper-text-otp-login">
                                    {errors.otp}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button fullWidth size="large" type="submit" variant="contained" color="secondary">
                                    {t('Submit')}
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default OTPComponent;
