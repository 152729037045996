import React, {useState, useContext, useEffect} from 'react';

import { useNavigate } from 'react-router-dom';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack, Link,
    Typography
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports

import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import ios_store from 'assets/images/ios.svg';
import android_store from 'assets/images/gplay.svg';
import qr_android_store from 'assets/images/qr-code-android.png';
import qr_ios_store from 'assets/images/qr-code-ios.png';

import {enable2FaQR, generate2FaQR, login} from 'api/api';

import { toast } from 'react-toastify';
import ToastContext from 'context/ToastContext';
import moment from "moment";
import {useTranslation} from "react-i18next";
import {SET_FONT_FAMILY} from "../../store/actions";
// ============================|| FIREBASE - LOGIN ||============================ //

const Google2FAComponent = ({ ...others }) => {
    const toastOptions = useContext(ToastContext);
    let navigate = useNavigate();
    const theme = useTheme();

    const [isLoading, setIsLoading] = useState(true);

    const { t, i18n } = useTranslation();

    const [authQR, setAuthQR] = useState();
    const [secretKey, setSecretKey] = useState();


    useEffect(() => {
        generate2FaQR({email: JSON.parse(localStorage.getItem('auth_user'))?.user?.email}).then((res) => {
            const response = res.data;
            setAuthQR(response.data.qr_code);
            setSecretKey(response.data.secret_key);
            setIsLoading(false)
        });
    }, []);

    return (
        isLoading?<>

        </>:<>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">{t('Setup Google Authenticator')}</Typography>
                    </Box>
                </Grid>
            </Grid>

            <Formik
                initialValues={{
                    user_id: JSON.parse(localStorage.getItem('auth_user'))?.user?._id,
                    secret_key: secretKey,
                    token: ''
                }}
                validationSchema={Yup.object().shape({
                    token: Yup.string().max(255).required(t('OTP is required')),
                })}
                onSubmit={(values) => {
                    enable2FaQR(values)
                        .then((res) => {
                            if(res.data.code === 1){
                                toast.success(i18n.language==='ar'?res.data.ar_message:res.data.en_message, toastOptions);



                                localStorage.setItem(
                                    'auth_user',
                                    JSON.stringify({
                                        user: res.data.user,
                                        time_stamp: moment().add(1, 'days').unix()
                                    })
                                );


                                navigate('/dashboard');


                            }
                            else{
                                toast.error(i18n.language==='ar'?res.data.ar_message:res.data.en_message, toastOptions);
                            }
                        })
                        .catch((err) => {
                            if (err?.response?.status === 401) {
                                toast.error(err.response?.data?.error, toastOptions);
                            }
                        });
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <Grid container spacing={2}>
                            <Grid item lg={4} md={4} xs={12}>
                                <img src={authQR} alt="qr code" />
                            </Grid>
                            <Grid item lg={8} md={8} xs={12}>
                                <p>{t('Set up your two factor authentication by scanning the barcode below. Alternatively, you can use the code')}</p>
                                <p style={{fontWeight: 'bold'}}>{secretKey}</p>
                                <Typography sx={{mb: 1}}>{t('Links for the authenticator applications')}</Typography>
                                <Grid container spacing={1}>
                                    <Grid item sm={6} md={6} lg={6} xs={12}>
                                        <Link href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">
                                            <img src={ios_store} alt="ios"/>
                                        </Link>
                                    </Grid>
                                    <Grid item sm={6} md={6} lg={6} xs={12}>
                                        <Link href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
                                            <img src={android_store} alt="android"/>
                                        </Link>
                                    </Grid>
                                    <Grid item sm={6} md={6} lg={6} xs={12}>
                                        <img src={qr_ios_store} alt="ios qr" style={{width: '130px'}} />
                                    </Grid>
                                    <Grid item sm={6} md={6} lg={6} xs={12}>
                                        <img src={qr_android_store} alt="android qr" style={{width: '130px'}} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <FormControl fullWidth error={Boolean(touched.token && errors.token)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-login">{t('OTP')}</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-token-login"
                                type="text"
                                value={values.token}
                                name="token"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label={t('Enter OTP')}
                                inputProps={{}}
                            />
                            {touched.token && errors.token && (
                                <FormHelperText error id="standard-weight-helper-text-token-login">
                                    {errors.token}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button fullWidth size="large" type="submit" variant="contained" color="secondary">
                                    {t('Activate')}
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default Google2FAComponent;
