import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {getalladminAppSetting, getAllInquiry, getCategory, getNotifications} from "../../../../../api/api";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAllNotification} from "../../../../../store/actions";


// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const location = useLocation();

    // const [notifications, setNotifications] = useState();
    const notifications = useSelector(state => state.notification?.data);
    const navigate = useNavigate();

    useEffect(() => {
        getAlerts();

        const interval = setInterval(() => getAlerts(), 100000)
        return () => {
            clearInterval(interval);
        }
    }, [location?.pathname]);

    const getAlerts = () => {
        var user_id = JSON.parse(localStorage.getItem('auth_user'))?.user?._id
        if(user_id){
            dispatch(getAllNotification({user_id: user_id}));
        }
        else{
            navigate('/logout')
        }

    }


    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} notifications={notifications} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} notification={notifications} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <List
                subheader={
                    item.title && (
                        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                            {i18n.language==='ar'?item.title_ar:item.title_en}
                            {item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            <Divider sx={{ mt: 0.25, mb: 1.25 }} />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
