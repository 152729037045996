import React, {useContext, useEffect, useMemo, useState} from 'react';
import {
    Box,
    Button,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Switch,
    TextField,
    Tooltip,
    Typography, Select as MSelect, Link
} from '@mui/material';
import {gridSpacing} from 'store/constant';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {useNavigate, useParams} from 'react-router-dom';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import DropZone from 'ui-component/DropZone';
import LoadingButton from '@mui/lab/LoadingButton';
import {toast} from 'react-toastify';
import ToastContext from 'context/ToastContext';
import * as Yup from 'yup';
import {Field, FieldArray, Formik} from 'formik';
import AWS from 'aws-sdk';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';
import {
    deleteVariationProducts, getalladminAppSetting,
    getAllCategoriesListbyService,
    getEditProduct,
    getServiceById,
    saveProduct,
    updateProduct
} from 'api/api';
import {useTranslation} from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from '@mui/icons-material/Info';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import imageCompression from 'browser-image-compression';
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";

const settings = JSON.parse(localStorage.getItem('settings'));

const decimal_patterns = [
    '0.1',
    '0.2',
    '0.3',
    '0.4',
    '0.5',
    '0.6',
    '0.7',
    '0.8',
    '0.9',
];


const EditProduct = () => {
    const { t, i18n } = useTranslation();
    let currentUser = JSON.parse(localStorage.getItem('auth_user')).user;
    const toastOptions = useContext(ToastContext);
    let navigate = useNavigate();
    let service = useParams();
    const [ProductImage, setProductImage] = useState();
    const [RentHourly, setRentHourly] = useState('');
    const [RentDaily, setRentDaily] = useState('');
    const [RentWeekly, setRentWeekly] = useState('');
    const [RentMonthly, setRentMonthly] = useState('');
    const [Images, setImages] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [UnitType, setUnitType] = useState('');
    const [subCategoryId, setSubCategoryId] = useState('');
    const [subSubCategoryId, setSubSubCategoryId] = useState('');
    const [ProductCategory, setProductCategory] = useState([]);
    const [Service, setService] = useState([]);
    const [Product, setProduct] = useState([]);
    const [UnitsData, setUnitsData] = useState([]);
    const [OriginsData, setOriginsData] = useState([]);
    const [ColorsData, setColorsData] = useState([]);
    const [ButtonLoading, setButtonLoading] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [SpecUnitData, setSpecUnitData] = useState([]);
    const [YearData, setYearData] = useState([]);
    const service_id = localStorage.getItem('serv-id');
    const product_id = localStorage.getItem('pro-id');
    const emptyMediaList_arr = { url: ''};
    const emptySpecification_arr = { key_en: '', key_ar: '', value: '' };
    const [variationOpen, setVariationOpen] = useState(false);
    const [variationActive, setVariationActive] = useState(false);
    const [otherVariationActive, setOtherVariationActive] = useState(false);
    const [ProductId, setProductId] = useState();
    const [ParentId, setParentId] = useState();
    const [productValues, setProductValues] = useState();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [variantProductId, setVariantProductId] = useState();
    const [unitOpen, setUnitOpen] = useState(false);
    const [isUnitAdd, setIsUnitAdd] = useState(false);
    const [unitSelect, setUnitSelect] = useState();
    const [delUnitId, setDelUnitId] = useState();
    const [unitDeleteOpen, setUnitDeleteOpen] = useState(false);
    const [categoryOpen, setCategoryOpen] = useState(false);
    const [subCategoryOpen, setSubCategoryOpen] = useState(false);
    const [subSubCategoryOpen, setSubSubCategoryOpen] = useState(false);
    const [isCatAdd, setIsCatAdd] = useState(false);
    const [isSubCatAdd, setIsSubCatAdd] = useState(false);
    const [isSubSubCatAdd, setIsSubSubCatAdd] = useState(false);
    const [customCategoryId, setCustomCategoryId] = useState(false);
    const [customSubCategoryId, setCustomSubCategoryId] = useState(false);
    const [settings, setAppSetting] = useState(false);

    const unitColumns = useMemo(() => [
        {
            field: 'title_en', headerName: t('Name English'), flex: 1,
            renderCell: ({row}) => {
                return (i18n.language==='ar'?row.title_ar:row.title_en);
            },
        },
        {
            field: 'title_ar', headerName: t('Name Arabic'), flex: 1,
            renderCell: ({row}) => {
                return (i18n.language==='ar'?row.title_ar:row.title_en);
            },
        },
        {
            field: 'action',
            headerName: t('Action'),
            type: 'actions',
            getActions: ({ row }) => [
                row.type && row.type==='CUSTOM'? (
                    <>
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="delete"
                            onClick={() => {
                                setDelUnitId(row._id);
                                setUnitDeleteOpen(true)

                            }
                            }
                        />
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="edit"
                            onClick={() =>
                            {
                                setIsUnitAdd(true);
                                setUnitSelect(row);
                            }
                            }
                        />
                    </>
                ):(<></>),
            ]
        }
    ]);
    const categoryColumns = useMemo(() => [
        {
            field: 'title_en', headerName: t('Name English'), flex: 1,
            renderCell: ({row}) => {
                return (i18n.language==='ar'?row.title_ar:row.title_en);
            },
        },
        {
            field: 'title_ar', headerName: t('Name Arabic'), flex: 1,
            renderCell: ({row}) => {
                return (i18n.language==='ar'?row.title_ar:row.title_en);
            },
        },
    ]);


    const [formState, setFormState] = React.useState({
        patterns: []
    });

    useEffect(() => {

        getEditProduct({product_id: product_id}).then((res) => {
            const response = res.data;
            if(response.code===1){
                setProduct(response.data);
                setCategoryId(response.data.category_id);
                setSubCategoryId(response.data.sub_category_id);
                setSubSubCategoryId(response.data.sub_sub_category_id);
                setImages(response.data.media);
                setFormState({
                    patterns: response.data.pattern
                });
                // setFormState(response.data.pattern)
                setUnitType(response.data.unit_type);
                if(response.data.service_tag === process.env.REACT_APP_EQUIPMENT_RENT){
                    const rent_rates = response.data.rent_rates
                    for(const rent of rent_rates){
                        if(rent.rate_tag === process.env.REACT_APP_RENT_HOURLY){
                            setRentHourly(rent.rate_value);
                        }
                        if(rent.rate_tag === process.env.REACT_APP_RENT_DAILY){
                            setRentDaily(rent.rate_value);
                        }
                        if(rent.rate_tag === process.env.REACT_APP_RENT_WEEKLY){
                            setRentWeekly(rent.rate_value);
                        }
                        if(rent.rate_tag === process.env.REACT_APP_RENT_MONTHLY){
                            setRentMonthly(rent.rate_value);
                        }
                    }
                }
            }
            else{
                toast.error(i18n.language==='ar'?response.ar_message:response.en_message);
            }
        });
        getServiceById({ service_id: service_id }).then((res) => {
            setService(res.data.data);
        });
        getAllCategoriesListbyService({ service_id: service_id }).then((res) => {
            const response = res.data;
            setProductCategory(response.data.category_arr);
            setColorsData(response.data.color_arr);
            setUnitsData(response.data.unit_arr);
            setOriginsData(response.data.origin_arr);
            setSpecUnitData(response.data.spec_unit_arr);
            setYearData(response.data.model_year_arr);
            setLoading(false)
        });
        getalladminAppSetting().then((res) => {
            setAppSetting(res.data.data);
        });
    }, []);

    const [uploadLink, setuploadLink] = useState([]);

    const handleFileUpload = async (files) => {
        try {

            AWS.config.update({
                accessKeyId: settings.aws_access_key,
                secretAccessKey: settings.aws_secret_key,
                region: settings.aws_region
            });

            const s3 = new AWS.S3();
            const uploadPromises = [];

            for (let file of files) {
                const options = {
                    maxSizeMB: 1,
                }
                file = await imageCompression(file, options);
                let extension = file.name.split('.').pop();

                const params = {
                    ACL: 'public-read',
                    Bucket: settings.aws_bucket_name,
                    Key: `omran/product_media/${Date.parse(new Date())}.${extension}`,
                    Body: file
                };

                const uploadPromise = s3.upload(params).promise();
                uploadPromises.push(uploadPromise);
            }

            const responses = await Promise.all(uploadPromises);
            const uploadLinks = responses.map((response) => response.Location);
            setuploadLink(uploadLinks);
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    const handleImageDelete = (e, index) => {
        try {
            const newArr = Images.filter((object,i) => {
                return i !== index;
            });
            setImages(newArr)
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    const handleFieldChange = event => {
        setFormState(formState => ({
            ...formState,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value
        }));
    };

    const handleVariationClick = (product_id) => {
        getEditProduct({product_id: product_id}).then((res) => {
            const response = res.data;
            if(response.code===1){
                setProduct(response.data);
                setCategoryId(response.data.category_id);
                setSubCategoryId(response.data.sub_category_id);
                setSubSubCategoryId(response.data.sub_sub_category_id);
                setImages(response.data.media);
                setFormState({
                    patterns: response.data.pattern
                });
                // setFormState(response.data.pattern)
                setUnitType(response.data.unit_type);
                if(response.data.service_tag === process.env.REACT_APP_EQUIPMENT_RENT){
                    const rent_rates = response.data.rent_rates
                    for(const rent of rent_rates){
                        if(rent.rate_tag === process.env.REACT_APP_RENT_HOURLY){
                            setRentHourly(rent.rate_value);
                        }
                        if(rent.rate_tag === process.env.REACT_APP_RENT_DAILY){
                            setRentDaily(rent.rate_value);
                        }
                        if(rent.rate_tag === process.env.REACT_APP_RENT_WEEKLY){
                            setRentWeekly(rent.rate_value);
                        }
                        if(rent.rate_tag === process.env.REACT_APP_RENT_MONTHLY){
                            setRentMonthly(rent.rate_value);
                        }
                    }
                }
            }
            else{
                toast.error(i18n.language==='ar'?response.ar_message:response.en_message);
            }
        });
    }

    const handleVariationClose = () => {
        setVariationOpen(false)
    }

    const handleDeleteClose = () => {
        setDeleteOpen(false)
    }

    const handleProductSave = (variationValues) => {
        let values = productValues;



        let unit_type_id = values.unit_type;
        if(Service.tag !== process.env.REACT_APP_EQUIPMENT_RENT && values.is_price_display) {
            let unit = UnitsData.find((e) => e._id === unit_type_id);
            values.unit_type = unit.title_en;
        }


        if(Product.variation_arr && Product.variation_arr.length === 0){
            values.edit_variation_name_en = variationValues.variation_name_en;
            values.edit_variation_name_ar = variationValues.variation_name_ar;

            setButtonLoading(true);
            updateProduct(values)
                .then((res) => {
                    values.unit_type = unit_type_id;
                    const response = res.data;
                    setButtonLoading(false);
                    if(response.code===1){
                        toast.success(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                        setProduct(response.data);
                        setCategoryId(response.data.category_id);
                        setSubCategoryId(response.data.sub_category_id);
                        setSubSubCategoryId(response.data.sub_sub_category_id);
                        setImages(response.data.media);
                        setFormState({
                            patterns: response.data.pattern
                        });
                        // setFormState(response.data.pattern)
                        setUnitType(response.data.unit_type);
                        if(response.data.service_tag === process.env.REACT_APP_EQUIPMENT_RENT){
                            const rent_rates = response.data.rent_rates
                            for(const rent of rent_rates){
                                if(rent.rate_tag === process.env.REACT_APP_RENT_HOURLY){
                                    setRentHourly(rent.rate_value);
                                }
                                if(rent.rate_tag === process.env.REACT_APP_RENT_DAILY){
                                    setRentDaily(rent.rate_value);
                                }
                                if(rent.rate_tag === process.env.REACT_APP_RENT_WEEKLY){
                                    setRentWeekly(rent.rate_value);
                                }
                                if(rent.rate_tag === process.env.REACT_APP_RENT_MONTHLY){
                                    setRentMonthly(rent.rate_value);
                                }
                            }
                        }
                        setVariationOpen(false)
                        if(!otherVariationActive){
                            let end_point = Service?.title_en.toLowerCase().replaceAll(' ','-');
                            end_point = end_point.replaceAll(/[.,\s]/g,'');
                            navigate(`/Products`);
                        }
                    }
                    else{
                        toast.error(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                    }
                })
                .catch((err) => {
                    values.unit_type = unit_type_id;
                    setButtonLoading(false);
                    if (err?.response?.status === 401) {
                        toast.error(err.response?.data?.error, toastOptions);
                    }
                });
        }
        else{
            values.variation_name_en = variationValues.variation_name_en;
            values.variation_name_ar = variationValues.variation_name_ar;
            values.parent_id = Product.parent_id ? Product.parent_id : Product._id;
            setButtonLoading(true);
            saveProduct(values)
                .then((res) => {
                    values.unit_type = unit_type_id;
                    const response = res.data;
                    setButtonLoading(false);
                    if(response.code===1){
                        toast.success(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                        setProduct(response.data);
                        setCategoryId(response.data.category_id);
                        setSubCategoryId(response.data.sub_category_id);
                        setSubSubCategoryId(response.data.sub_sub_category_id);
                        setImages(response.data.media);
                        setFormState({
                            patterns: response.data.pattern
                        });
                        // setFormState(response.data.pattern)
                        setUnitType(response.data.unit_type);
                        if(response.data.service_tag === process.env.REACT_APP_EQUIPMENT_RENT){
                            const rent_rates = response.data.rent_rates
                            for(const rent of rent_rates){
                                if(rent.rate_tag === process.env.REACT_APP_RENT_HOURLY){
                                    setRentHourly(rent.rate_value);
                                }
                                if(rent.rate_tag === process.env.REACT_APP_RENT_DAILY){
                                    setRentDaily(rent.rate_value);
                                }
                                if(rent.rate_tag === process.env.REACT_APP_RENT_WEEKLY){
                                    setRentWeekly(rent.rate_value);
                                }
                                if(rent.rate_tag === process.env.REACT_APP_RENT_MONTHLY){
                                    setRentMonthly(rent.rate_value);
                                }
                            }
                        }
                        setVariationOpen(false)
                        if(!otherVariationActive){
                            let end_point = Service?.title_en.toLowerCase().replaceAll(' ','-');
                            end_point = end_point.replaceAll(/[.,\s]/g,'');
                            navigate(`/Products`);
                        }
                    }
                    else{
                        toast.error(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                    }
                })
                .catch((err) => {
                    values.unit_type = unit_type_id;
                    setButtonLoading(false);
                    if (err?.response?.status === 401) {
                        toast.error(err.response?.data?.error, toastOptions);
                    }
                });
        }
    }

    const handleUnitDeleteClose = () => {
        setUnitDeleteOpen(false);
    };

    const handleUnitClose = () => {
        setUnitOpen(false)
    }

    const handleCategoryClose = () => {
        setCategoryOpen(false)
    }

    const handleSubCategoryClose = () => {
        setSubCategoryOpen(false)
    }

    const handleSubSubCategoryClose = () => {
        setSubSubCategoryOpen(false)
    }


    return (
        <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12}>
                {isLoading ? (
                    <SkeletonEarningCard />
                ) : (
                    <Card sx={{ minWidth: 250, cursor: 'pointer' }} elevation={2}>
                        <CardContent>
                            <Grid container spacing={gridSpacing}>
                                <Grid item lg={12}>
                                    <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
                                        <Box>
                                            <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{t('Products')}</Typography>
                                            <Typography sx={{ color: 'gray' }}>{t('Edit Product')}</Typography>
                                        </Box>
                                        <Box>
                                            <Button
                                                onClick={() => navigate(-1)}
                                                sx={{
                                                    backgroundColor: '#0e3152',
                                                    color: 'white',
                                                    mt: 0.7,
                                                    '&:hover': {
                                                        backgroundColor: '#7b8ea0'
                                                    }
                                                }}
                                            >
                                                {t('Back')}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
                {isLoading ? (
                    <SkeletonEarningCard />
                ) : (
                    <Formik key={1}
                        enableReinitialize={true}
                        initialValues={{
                            parent_id: Product.parent_id ? Product.parent_id : Product._id,
                            name_en: Product.name_en,
                            name_ar: Product.name_ar,
                            desc_en: Product.desc_en,
                            desc_ar: Product.desc_ar,
                            category: Product.category_id,
                            sub_category: Product.sub_category_id!=null?Product.sub_category_id:'',
                            sub_sub_category: Product.sub_sub_category_id!=null?Product.sub_sub_category_id:'',
                            category_id: Product.category_id,
                            sub_category_id: Product.sub_category_id!=null?Product.sub_category_id:'',
                            sub_sub_category_id: Product.sub_sub_category_id!=null?Product.sub_sub_category_id:'',
                            is_unit_decimal: Product.is_unit_decimal,
                            is_price_display: Product.is_price_display,
                            pattern: Product.pattern,
                            service_id: service_id,
                            user_id: Product?.user_id,
                            company_id: Product?.company_id,
                            media: Product.media,//[],
                            delivery_city: [],
                            unit: '1',
                            unit_type: Product.unit_type!=='' && Product.unit_type!==null &&
                                UnitsData.find((e) => e.title_en.toLowerCase() === Product.unit_type.toLowerCase())?UnitsData.find((e) => e.title_en.toLowerCase() === Product.unit_type.toLowerCase())._id:'',
                            unit_price: Product.unit_price,
                            cost_per_unit: Product.cost_per_unit,
                            margin: Product.margin,
                            sku: Product.sku,
                            item_code: Product.item_code,
                            barcode: Product.barcode,
                            media_links: Product.media_links && Product.media_links.length ===0 ?[emptyMediaList_arr]:Product.media_links,
                            specification_arr: Product.specification_arr && Product.specification_arr.length > 0?Product.specification_arr:[emptySpecification_arr],
                            url1: '',
                            url2: '',
                            url3: '',
                            brand_name: Product.brand_name,
                            product_color: Product.product_color,
                            material: Product.material,
                            origin: Product.origin,
                            for_use_on: Product.for_use_on,
                            offer: Product.offer,
                            product_weight: Product.product_weight,
                            price_after_offer: Product.price_after_offer,
                            movement_type: Product.movement_type,
                            modal_year: Product.modal_year,
                            category_value: Product.category_value,
                            condition_value: Product.condition_value,
                            usage_hours: Product.usage_hours,
                            manufacturer_name: Product.manufacturer_name,

                            hourly: RentHourly,
                            daily: RentDaily,
                            weekly: RentWeekly,
                            monthly: RentMonthly,
                            status: Product.status,
                            seo_keywords: Product.seo_keywords,
                            seo_desc_en: Product.seo_desc_en,
                            seo_desc_ar: Product.seo_desc_ar,
                            seo_url: Product.seo_url,
                            stock: Product.stock,
                            product_id: Product._id,
                            height: Product.height,
                            width: Product.width,
                            length: Product.length,
                            spec_unit: Product.spec_unit,
                            edit_variation_name_en: Product.variation_arr && Product.variation_arr.length>0?Product.variation_arr.find((e) => e.is_selected).variation_name_en:'',
                            edit_variation_name_ar: Product.variation_arr && Product.variation_arr.length>0?Product.variation_arr.find((e) => e.is_selected).variation_name_ar:'',
                            variation_id: Product.variation_arr && Product.variation_arr.length>0?Product.variation_arr.find((e) => e.is_selected)._id:'',
                            app_platform: process.env.REACT_APP_PLATFORM,
                            logs: {
                                user: currentUser,
                                time: moment().format(),
                                lat: localStorage.getItem('lat'),
                                lng: localStorage.getItem('lng'),
                            }
                        }}
                        validationSchema={
                            Service.tag !== process.env.REACT_APP_EQUIPMENT_RENT?Yup.object().shape({
                                    name_en: Yup.string().max(255).required(t('Name is required')),
                                    name_ar: Yup.string().max(255).required(t('Name is required')),
                                    desc_en: Yup.string().max(255).required(t('Description English is required')),
                                    desc_ar: Yup.string().max(255).required(t('Description Arabic is required')),
                                    category: Yup.string().max(255).required(t('Category is required')),
                                    // product_weight: Yup.string().max(255).required('Weight is required'),
                                    unit_type: Yup.string().
                                    when("is_price_display", {
                                        is: (is_price_display) => is_price_display,
                                        then: () => Yup.string().max(255).required(t('Unit Type is required'))
                                    }),
                                    unit_price: Yup.string().
                                    when("is_price_display", {
                                        is: (is_price_display) => is_price_display,
                                        then: () => Yup.string().required(t('Unit Price is required'))
                                    }),
                                    // offer: Yup.number().min(0).max(99).nullable(),
                                // url: Yup.array().of(
                                //     Yup.string().matches(
                                //         /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                                //         'Enter correct url!'
                                //     )
                                // )
                            }):
                                Yup.object().shape({
                                    name_en: Yup.string().max(255).required(t('Name is required')),
                                    name_ar: Yup.string().max(255).required(t('Name is required')),
                                    desc_en: Yup.string().max(255).required(t('Description English is required')),
                                    desc_ar: Yup.string().max(255).required(t('Description Arabic is required')),
                                    category: Yup.string().max(255).required(t('Category is required')),
                                    // hourly: Yup.string().max(255).required(t('Monthly rate is required')),
                                    // daily: Yup.string().max(255).required(t('Daily rate is required')),
                                    // weekly: Yup.string().max(255).required(t('Weekly rate is required')),
                                    // monthly: Yup.string().max(255).required(t('Monthly rate is required')),
                                    hourly: Yup.string().
                                        when("is_price_display", {
                                            is: (is_price_display) => is_price_display,
                                            then: () => Yup.string().required(t('Hourly rate is required')),
                                        }),
                                    daily: Yup.string().
                                        when("is_price_display", {
                                            is: (is_price_display) => is_price_display,
                                            then: () => Yup.string().required(t('Daily rate is required')),
                                        }),
                                    weekly: Yup.string().
                                        when("is_price_display", {
                                            is: (is_price_display) => is_price_display,
                                            then: () => Yup.string().required(t('Weekly rate is required')),
                                        }),
                                    monthly: Yup.string().
                                        when("is_price_display", {
                                            is: (is_price_display) => is_price_display,
                                            then: () => Yup.string().required(t('Monthly rate is required')),
                                        }),
                                    // product_weight: Yup.string().max(255).required('Weight is required'),
                                    // unit_type: Yup.string().max(255).required('Unity Type is required'),
                                })
                        }
                        onSubmit={(values) => {
                            let isValid = true
                            if(Product.variation_arr && Product.variation_arr.length>0){
                                if(values.edit_variation_name_en ===''){
                                    isValid = false;
                                    toast.error(t('English variation name is required'));
                                }
                                if(values.edit_variation_name_ar ===''){
                                    isValid = false;
                                    toast.error(t('Arabic variation name is required'));
                                }
                            }
                            if(isValid) {
                                if (values.cost_per_unit > values.price_per_unit) {
                                    toast.error(t('Cost price must be less than unit price'));
                                }
                                values.category_id = values.category;
                                values.sub_category_id = values.sub_category;
                                values.sub_sub_category_id = values.sub_sub_category;
                                if (values.sub_category_id === '') {
                                    values.sub_category_id = null;
                                }
                                if (values.sub_sub_category_id === '') {
                                    values.sub_sub_category_id = null;
                                }
                                if (values.condition_value === 'NEW') {
                                    values.usage_hours = null
                                }
                                values.pattern = formState.patterns;
                                values.media = uploadLink.map((e, index) => ({id: index + 1, img: e}));
                                values.media = [...Images, ...values.media];
                                // Array.prototype.push.apply(values.media,Images);
                                // values.media_links = [{ url1: values.url1 }, { url2: values.url2 }, { url3: values.url3 }];
                                if (Service.tag !== process.env.REACT_APP_EQUIPMENT_RENT) {
                                    values.price_after_offer =
                                        values.unit_price - (values.unit_price * values.offer) / 100
                                            ? values.unit_price - (values.unit_price * values.offer) / 100
                                            : values.unit_price;
                                    values.margin = values.unit_price - values.cost_per_unit;
                                } else {
                                    values.rent_rates = [
                                        {
                                            rate_tag: 'HOURLY',
                                            rate_value: values.hourly,
                                        },
                                        {
                                            rate_tag: 'DAILY',
                                            rate_value: values.daily,
                                        },
                                        {
                                            rate_tag: 'WEEKLY',
                                            rate_value: values.weekly,
                                        },
                                        {
                                            rate_tag: 'MONTHLY',
                                            rate_value: values.monthly,
                                        },
                                    ]
                                }


                                if (values.media.length <= 0) {
                                    toast.error('Image is required', toastOptions);
                                } else {
                                    if (variationActive) {
                                        setVariationOpen(true)
                                        setProductValues(values);
                                    } else {
                                        let unit_type_id = values.unit_type;
                                        if (Service.tag !== process.env.REACT_APP_EQUIPMENT_RENT && values.is_price_display) {
                                            let unit = UnitsData.find((e) => e._id === unit_type_id);
                                            values.unit_type = unit.title_en;
                                        }
                                        setButtonLoading(true);
                                        updateProduct(values)
                                            .then((res) => {
                                                values.unit_type = unit_type_id;
                                                const response = res.data;
                                                setButtonLoading(false);
                                                if (response.code === 1) {
                                                    toast.success(response.en_message, toastOptions);

                                                    navigate(`/Products`);
                                                    localStorage.removeItem('pro-id');
                                                    localStorage.removeItem('serv-id');
                                                } else {
                                                    toast.error(i18n.language === 'ar' ? response.ar_message : response.en_message, toastOptions);
                                                }
                                            })
                                            .catch((err) => {
                                                values.unit_type = unit_type_id;
                                                if (err?.response?.status === 401) {
                                                    setButtonLoading(false);
                                                    toast.error(err.response?.data?.error, toastOptions);
                                                }
                                            });
                                    }
                                }
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Card sx={{ minWidth: 250 }}>
                                            <CardContent>
                                                <Grid container spacing={gridSpacing}>
                                                    <Grid item lg={4} md={6} sm={12}>
                                                        <Typography sx={{ mb: 1 }}>{t('Status')}</Typography>
                                                        <TextField
                                                            select
                                                            name="status"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            data={values.status}
                                                            value={values.status}
                                                            sx={{ width: '100%' }}
                                                        >
                                                            <MenuItem key={0} value={true}>{t('Active')}</MenuItem>
                                                            <MenuItem key={1} value={false}>{t('Inactive')}</MenuItem>
                                                        </TextField>
                                                    </Grid>
                                                    {
                                                        Product.variation_arr && Product.variation_arr.length>0?(
                                                            <Grid item lg={12} md={12} sm={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item lg={12}>
                                                                        <Typography variant="h4">{t('Select Variation')}</Typography>
                                                                        <Divider sx={{ my: 2 }} />
                                                                    </Grid>
                                                                    {
                                                                        Product.variation_arr.map((e, index) => {
                                                                            return (
                                                                                <Grid item lg={2} md={2} sm={2} key={e._id} sx={{ml:2, mb:2}} style={{position: 'relative', display:'flex'}}>
                                                                                    {
                                                                                        index > 0?(
                                                                                            <IconButton
                                                                                                onClick={() => {
                                                                                                    setVariantProductId(e.product_id)
                                                                                                    setDeleteOpen(true)
                                                                                                }
                                                                                                }
                                                                                                style={{top:'-10px', left: '-10px',position: 'absolute', color: 'red'}} aria-label="delete"  color="danger">
                                                                                                <DeleteIcon />
                                                                                            </IconButton>
                                                                                        ):(
                                                                                            <></>
                                                                                        )
                                                                                    }
                                                                                    <Button style={{textTransform: 'none'}} key={e._id} color="secondary" fullWidth variant={e.is_selected?'contained':'outlined'}
                                                                                        onClick={() => handleVariationClick(e.product_id)}
                                                                                    >
                                                                                        {i18n.language==='ar'?e.variation_name_ar:e.variation_name_en}
                                                                                    </Button>
                                                                                </Grid>
                                                                            )
                                                                        })
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        ):(
                                                            <></>
                                                        )
                                                    }
                                                    {
                                                        Product.variation_arr && Product.variation_arr.length>0?(
                                                            <Grid item lg={12} md={12} sm={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item lg={12}>
                                                                        <Typography variant="h4">{t('Variation')}</Typography>
                                                                        <Divider sx={{ mt: 2 }} />
                                                                    </Grid>
                                                                    <Grid item lg={6} md={6}>
                                                                        <Typography sx={{ mb: 1 }}>{t('Variation Name English')}</Typography>
                                                                        <TextField
                                                                            placeholder={t('Variation Name English')}
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            name="edit_variation_name_en"
                                                                            value={values.edit_variation_name_en}
                                                                            error={Boolean(touched.edit_variation_name_en && errors.edit_variation_name_en)}
                                                                        />
                                                                        {touched.edit_variation_name_en && errors.edit_variation_name_en && (
                                                                            <FormHelperText error id="standard-weight-helper-text-edit_variation_name_en-login">
                                                                                {errors.edit_variation_name_en}
                                                                            </FormHelperText>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item lg={6} md={6}>
                                                                        <Typography sx={{ mb: 1 }}>{t('Variation Name Arabic')}</Typography>
                                                                        <TextField
                                                                            placeholder={t('Variation Name Arabic')}
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            name="edit_variation_name_ar"
                                                                            value={values.edit_variation_name_ar}
                                                                            error={Boolean(touched.edit_variation_name_ar && errors.edit_variation_name_ar)}
                                                                        />
                                                                        {touched.edit_variation_name_ar && errors.edit_variation_name_ar && (
                                                                            <FormHelperText error id="standard-weight-helper-text-edit_variation_name_ar-login">
                                                                                {errors.edit_variation_name_ar}
                                                                            </FormHelperText>
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ):(
                                                            <></>
                                                        )
                                                    }
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12}>
                                                                <Typography variant="h4">{t('Product Name')}</Typography>
                                                                <Divider sx={{ mt: 2 }} />
                                                            </Grid>
                                                            <Grid item lg={6} md={6}>
                                                                <Typography sx={{ mb: 1 }}>{t('Name English')}<span style={{color: 'red'}}>*</span></Typography>
                                                                <TextField
                                                                    placeholder={t('Name English')}
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="name_en"
                                                                    value={values.name_en}
                                                                    error={Boolean(touched.name_en && errors.name_en)}
                                                                />
                                                                {touched.name_en && errors.name_en && (
                                                                    <FormHelperText error id="standard-weight-helper-text-name_en-login">
                                                                        {errors.name_en}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={6} md={6}>
                                                                <Typography sx={{ mb: 1 }}>{t('Name Arabic')}<span style={{color: 'red'}}>*</span></Typography>
                                                                <TextField
                                                                    placeholder={t('Name Arabic')}
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="name_ar"
                                                                    value={values.name_ar}
                                                                    error={Boolean(touched.name_ar && errors.name_ar)}
                                                                />
                                                                {touched.name_ar && errors.name_ar && (
                                                                    <FormHelperText error id="standard-weight-helper-text-name_ar-login">
                                                                        {errors.name_ar}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12}>
                                                                <Typography variant="h4">{t('Description')}</Typography>
                                                                <Divider sx={{ mt: 2 }} />
                                                            </Grid>
                                                            <Grid item lg={6} md={6}>
                                                                <Typography sx={{ mb: 1 }}>{t('Description English')}<span style={{color: 'red'}}>*</span></Typography>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={values.desc_en}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        setFieldValue('desc_en', data);
                                                                    }}
                                                                />
                                                                {touched.desc_en && errors.desc_en && (
                                                                    <FormHelperText error id="standard-weight-helper-text-desc_en-login">
                                                                        {errors.desc_en}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={6} md={6}>
                                                                <Typography sx={{ mb: 1 }}>{t('Description Arabic')}<span style={{color: 'red'}}>*</span></Typography>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={values.desc_ar}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        setFieldValue('desc_ar', data);
                                                                    }}
                                                                />
                                                                {touched.desc_ar && errors.desc_ar && (
                                                                    <FormHelperText error id="standard-weight-helper-text-desc_ar-login">
                                                                        {errors.desc_ar}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12}>
                                                                <Typography variant="h4">{t('Category')}</Typography>
                                                                <Divider sx={{ mt: 2 }} />
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={
                                                                    values.category === ''
                                                                        ? 12
                                                                        : values.category !== '' && values.sub_category == ''
                                                                            ? 6
                                                                            : 4
                                                                }
                                                                md={4}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <Typography sx={{ mb: 1 }}>{t('Select Category')}<span style={{color: 'red'}}>*</span></Typography>
                                                                <MSelect
                                                                    fullWidth
                                                                    name="category"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.category}
                                                                    error={Boolean(touched.category && errors.category)}
                                                                    placeholder={t('Select Category')}
                                                                >
                                                                    {/*<MenuItem onClick={() => setCategoryOpen(true)}><Link>{t('Add Category')}</Link></MenuItem>*/}
                                                                    {
                                                                        ProductCategory?.category && ProductCategory?.category.map((e) => (
                                                                            <MenuItem key={e._id} value={e._id}>{i18n.language==='ar'?e.title_ar:e.title_en}</MenuItem>
                                                                        ))
                                                                    }
                                                                </MSelect>
                                                                {/*<Select*/}
                                                                {/*    size="medium"*/}
                                                                {/*    name="category"*/}
                                                                {/*    defaultValue = ""*/}
                                                                {/*    onChange={handleChange}*/}
                                                                {/*    data={values.category === ''? categoryId :values.category}*/}
                                                                {/*    type="text"*/}
                                                                {/*    onBlur={handleBlur}*/}
                                                                {/*    error={Boolean(touched.category && errors.category)}*/}
                                                                {/*    defaultdata*/}
                                                                {/*    options={ProductCategory?.category}*/}
                                                                {/*/>*/}
                                                                {touched.category && errors.category && (
                                                                    <FormHelperText error id="standard-weight-helper-text-category-login">
                                                                        {errors.category}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                            {values.category !== '' && subCategoryId!==undefined ? (
                                                                <Grid
                                                                    item
                                                                    lg={values.sub_category === '' ? 6 : 4}
                                                                    md={values.sub_category === '' ? 6 : 4}
                                                                    sm={12}
                                                                    xs={12}
                                                                >
                                                                    <Typography sx={{ mb: 1 }}>{t('Select Sub Category')}</Typography>
                                                                    <MSelect
                                                                        fullWidth
                                                                        name="sub_category"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.sub_category}
                                                                        error={Boolean(touched.sub_category && errors.sub_category)}
                                                                        placeholder={t('Select Sub Category')}
                                                                    >
                                                                        {/*<MenuItem onClick={() => {*/}
                                                                        {/*    setCustomCategoryId(values.category)*/}
                                                                        {/*    setSubCategoryOpen(true);*/}
                                                                        {/*}*/}
                                                                        {/*}><Link>{t('Add Sub Category')}</Link></MenuItem>*/}
                                                                        {
                                                                            ProductCategory?.category?.find(
                                                                                (e) => e._id === values.category
                                                                            )?.sub_category && ProductCategory?.category?.find(
                                                                                (e) => e._id === values.category
                                                                            )?.sub_category.map((e) => (
                                                                                <MenuItem key={e._id} value={e._id}>{i18n.language==='ar'?e.title_ar:e.title_en}</MenuItem>
                                                                            ))
                                                                        }
                                                                    </MSelect>
                                                                    {/*<Select*/}
                                                                    {/*    size="medium"*/}
                                                                    {/*    name="sub_category"*/}
                                                                    {/*    onChange={handleChange}*/}
                                                                    {/*    onBlur={handleBlur}*/}
                                                                    {/*    data={values.sub_category === ''? subCategoryId :values.sub_category}*/}
                                                                    {/*    error={Boolean(touched.sub_category && errors.sub_category)}*/}
                                                                    {/*    type="text"*/}
                                                                    {/*    options={*/}
                                                                    {/*        ProductCategory?.category?.find(*/}
                                                                    {/*            (e) => e._id === values.category*/}
                                                                    {/*        )?.sub_category*/}
                                                                    {/*    }*/}
                                                                    {/*/>*/}
                                                                    {touched.sub_category && errors.sub_category && (
                                                                        <FormHelperText
                                                                            error
                                                                            id="standard-weight-helper-text-sub_category-login"
                                                                        >
                                                                            {errors.sub_category}
                                                                        </FormHelperText>
                                                                    )}
                                                                </Grid>
                                                            ) : (
                                                                ''
                                                            )}

                                                            {values.sub_category !== '' && subCategoryId!==undefined ? (
                                                                <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                    <Typography sx={{ mb: 1 }}>{t('Select Sub Sub Category')}</Typography>
                                                                    <MSelect
                                                                        fullWidth
                                                                        name="sub_sub_category"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.sub_sub_category !== ''? values.sub_sub_category :subSubCategoryId}
                                                                        error={Boolean(touched.sub_sub_category && errors.sub_sub_category)}
                                                                        placeholder={t('Select Sub Sub Category')}
                                                                    >
                                                                        {/*<MenuItem onClick={() => {*/}
                                                                        {/*    setCustomCategoryId(values.category)*/}
                                                                        {/*    setCustomSubCategoryId(values.sub_category)*/}
                                                                        {/*    setSubSubCategoryOpen(true);*/}
                                                                        {/*}*/}
                                                                        {/*}><Link>{t('Add Sub Sub Category')}</Link></MenuItem>*/}
                                                                        {
                                                                            ProductCategory?.category
                                                                                ?.find((e) => e._id === values.category)
                                                                                ?.sub_category?.find(
                                                                                (e) => e?._id === values.sub_category
                                                                            ) &&
                                                                            ProductCategory?.category
                                                                                ?.find((e) => e._id === values.category)
                                                                                ?.sub_category?.find(
                                                                                (e) => e?._id === values.sub_category
                                                                            ).sub_sub_category && ProductCategory?.category
                                                                                ?.find((e) => e._id === values.category)
                                                                                ?.sub_category?.find(
                                                                                    (e) => e?._id === values.sub_category
                                                                                ).sub_sub_category.map((e) => (
                                                                                    <MenuItem key={e._id} value={e._id}>{i18n.language==='ar'?e.title_ar:e.title_en}</MenuItem>
                                                                                ))
                                                                        }
                                                                    </MSelect>
                                                                    {/*<Select*/}
                                                                    {/*    size="medium"*/}
                                                                    {/*    name="sub_sub_category"*/}
                                                                    {/*    onChange={handleChange}*/}
                                                                    {/*    onBlur={handleBlur}*/}
                                                                    {/*    data={values.sub_sub_category === ''? subSubCategoryId :values.sub_sub_category}*/}
                                                                    {/*    type="text"*/}
                                                                    {/*    options={*/}
                                                                    {/*        ProductCategory?.category*/}
                                                                    {/*            ?.find((e) => e._id === values.category)*/}
                                                                    {/*            ?.sub_category?.find(*/}
                                                                    {/*            (e) => e?._id === values.sub_category*/}
                                                                    {/*        ).sub_sub_category*/}
                                                                    {/*    }*/}
                                                                    {/*/>*/}
                                                                </Grid>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12}>
                                                                <Typography variant="h4">{t('Product Image')}<span style={{color: 'red'}}>*</span></Typography>
                                                                <Divider sx={{ mt: 2, mb: 2 }} />
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                <DropZone onChange={handleFileUpload} limit={5} />
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{m: 2}}>
                                                                <Grid container spacing={2}>
                                                                    {
                                                                        Images.map(function(e,i) {
                                                                            return (

                                                                                <Grid item lg={3} md={6} sm={12} style={{position: 'relative', display:'flex'}}>
                                                                                    <IconButton
                                                                                        onClick={(e) => handleImageDelete(e,i)}
                                                                                        style={{top:'-10px', left: '-10px',position: 'absolute', color: 'red'}} aria-label="delete"  color="danger">
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                    <img src={e.img} width={'80px'}/>
                                                                                </Grid>
                                                                            );
                                                                        })
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12}>
                                                                <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
                                                                    <Typography variant="h4">{t('Price')}</Typography>
                                                                    {
                                                                        !Product.is_deliver_by_provider && !Product.is_collect_from_store?(
                                                                            <>
                                                                                <Box>
                                                                                    <FormControlLabel
                                                                                        value={values.is_price_display}
                                                                                        onChange={handleChange}
                                                                                        name="is_price_display"
                                                                                        control={
                                                                                            values.is_price_display===true ?(<Switch defaultChecked color="success" />):(<Switch  color="success" />)
                                                                                        }
                                                                                        label={t('Price Display')}
                                                                                        labelPlacement='start'
                                                                                    />
                                                                                </Box>
                                                                            </>
                                                                        ):(
                                                                            <>
                                                                                <Box>
                                                                                    <FormControlLabel
                                                                                        onChange={handleChange}
                                                                                        control={
                                                                                            values.is_price_display===true ?(<Switch defaultChecked color="success" disabled={true} />):(<Switch disabled={true}  color="success" />)
                                                                                        }
                                                                                        label={t('Price Display')}
                                                                                        labelPlacement='start'
                                                                                    />
                                                                                    <Tooltip title={t('PLEASE NOTE: You cannot modify price display because delivery or collect from store is enabled')}>
                                                                                        <IconButton sx={{p: 0}}>
                                                                                            <InfoIcon color="error" />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </Box>
                                                                            </>
                                                                        )
                                                                    }
                                                                </Box>
                                                                <Divider sx={{ mt: 2 }} />
                                                            </Grid>

                                                            {Service.tag !== process.env.REACT_APP_EQUIPMENT_RENT && values.is_price_display?
                                                                (
                                                                <>
                                                                    <Grid item lg={4} md={6} sm={12}>
                                                                        <Typography sx={{ mb: 1 }}>{t('Unit Type')}<span style={{color: 'red'}}>*</span></Typography>
                                                                        {/*<Select*/}
                                                                        {/*    size="medium"*/}
                                                                        {/*    name="unit_type"*/}
                                                                        {/*    onChange={handleChange}*/}
                                                                        {/*    onBlur={handleBlur}*/}
                                                                        {/*    data={values.unit_type!==''?values.unit_type:UnitType}*/}
                                                                        {/*    error={Boolean(touched.unit_type && errors.unit_type)}*/}
                                                                        {/*    type="text"*/}
                                                                        {/*    defaultdata*/}
                                                                        {/*    options={UnitsData}*/}
                                                                        {/*/>*/}
                                                                        <MSelect
                                                                            fullWidth
                                                                            name="unit_type"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.unit_type!==''?values.unit_type:UnitType}
                                                                            error={Boolean(touched.unit_type && errors.unit_type)}
                                                                            placeholder={t('Select Unit')}
                                                                        >
                                                                            <MenuItem onClick={() => setUnitOpen(true)}><Link>{t('Add Unit')}</Link></MenuItem>
                                                                            {
                                                                                UnitsData && UnitsData.map((e) => (
                                                                                    <MenuItem key={e._id} value={e._id}>{i18n.language==='ar'?e.title_ar:e.title_en}</MenuItem>
                                                                                ))
                                                                            }
                                                                        </MSelect>
                                                                        {touched.unit_type && errors.unit_type && (
                                                                            <FormHelperText error id="standard-weight-helper-text-unit_type-login">
                                                                                {errors.unit_type}
                                                                            </FormHelperText>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item lg={4} md={6} sm={12}>
                                                                        <Typography sx={{ mb: 1 }}>{t('Price Per Unit')}<span style={{color: 'red'}}>*</span> <small>({t('Exc. VAT')})</small></Typography>
                                                                        <TextField
                                                                            placeholder={t('Price Per Unit')}
                                                                            fullWidth
                                                                            type="number"
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            name="unit_price"
                                                                            value={values.unit_price}
                                                                            error={Boolean(touched.unit_price && errors.unit_price)}
                                                                        />
                                                                        {touched.unit_price && errors.unit_price && (
                                                                            <FormHelperText error id="standard-weight-helper-text-unit_price-login">
                                                                                {errors.unit_price}
                                                                            </FormHelperText>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item lg={4} md={6} sm={12}>
                                                                        <Typography sx={{ mb: 1 }}>{t('Product Weight')}</Typography>
                                                                        <TextField
                                                                            type="number"
                                                                            placeholder={t('Product Weight')}
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            name="product_weight"
                                                                            value={values.product_weight}
                                                                            error={Boolean(touched.product_weight && errors.product_weight)}
                                                                        />
                                                                        {touched.product_weight && errors.product_weight && (
                                                                            <FormHelperText
                                                                                error
                                                                                id="standard-weight-helper-text-product_weight-login"
                                                                            >
                                                                                {errors.product_weight}
                                                                            </FormHelperText>
                                                                        )}
                                                                    </Grid>
                                                                </>
                                                                ):
                                                                (
                                                                    <></>
                                                                )
                                                            }


                                                            {Service.tag !== process.env.REACT_APP_EQUIPMENT_RENT && values.is_price_display?
                                                                (
                                                                    <>

                                                                        <Grid item lg={12} md={12}>
                                                                            <FormControlLabel
                                                                                value={values.is_unit_decimal}
                                                                                onChange={handleChange}
                                                                                name="is_unit_decimal"
                                                                                control={
                                                                                    values.is_unit_decimal===true ?(<Switch defaultChecked color="success" />):(<Switch  color="success" />)
                                                                                }
                                                                                label={t('Unit accept decimal')}
                                                                                labelPlacement='start'
                                                                            />
                                                                            {values.is_unit_decimal && (

                                                                                <Grid item lg={4} md={6} sm={12}>
                                                                                    <Typography sx={{ mb: 1 }}>{t('Pattern')}</Typography>
                                                                                    <TextField
                                                                                        select
                                                                                        name="patterns"
                                                                                        id="patterns"
                                                                                        variant="outlined"
                                                                                        sx={{ width: 205 }}
                                                                                        SelectProps={{
                                                                                            multiple: true,
                                                                                            value: formState.patterns,
                                                                                            onChange: handleFieldChange
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            (decimal_patterns).map((e) => (
                                                                                                <MenuItem key={e} value={e}>{e}</MenuItem>
                                                                                            ))
                                                                                        }
                                                                                    </TextField>

                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                    </>
                                                                ):
                                                                values.is_price_display?(
                                                                    <>
                                                                        <Grid item lg={12} md={6} sm={12}>
                                                                            <Typography sx={{ mb: 1 }}>{t('Product Weight')}</Typography>
                                                                            <TextField
                                                                                type="number"
                                                                                placeholder={t('Product Weight')}
                                                                                fullWidth
                                                                                InputProps={{
                                                                                    endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                                name="product_weight"
                                                                                value={values.product_weight}
                                                                                error={Boolean(touched.product_weight && errors.product_weight)}
                                                                            />
                                                                            {touched.product_weight && errors.product_weight && (
                                                                                <FormHelperText
                                                                                    error
                                                                                    id="standard-weight-helper-text-product_weight-login"
                                                                                >
                                                                                    {errors.product_weight}
                                                                                </FormHelperText>
                                                                            )}
                                                                        </Grid>
                                                                        <Grid item lg={3} md={6} sm={12}>
                                                                            <Typography sx={{ mb: 1 }}>{t('Hourly')}<span style={{color: 'red'}}>*</span> (1)</Typography>
                                                                            <TextField
                                                                                placeholder={t('Hourly')}
                                                                                fullWidth
                                                                                type="number"
                                                                                InputProps={{ inputProps: { min: 0} }}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                                name="hourly"
                                                                                value={values.hourly!==''?values.hourly:RentHourly}
                                                                                error={Boolean(touched.hourly && errors.hourly)}
                                                                            />
                                                                            {touched.hourly && errors.hourly && (
                                                                                <FormHelperText error id="standard-weight-helper-text-hourly-login">
                                                                                    {errors.hourly}
                                                                                </FormHelperText>
                                                                            )}
                                                                        </Grid>
                                                                        <Grid item lg={3} md={6} sm={12}>
                                                                            <Typography sx={{ mb: 1 }}>{t('Daily')}<span style={{color: 'red'}}>*</span> ({t('24 hr')})</Typography>
                                                                            <TextField
                                                                                placeholder={t('Daily')}
                                                                                fullWidth
                                                                                type="number"
                                                                                InputProps={{ inputProps: { min: 0} }}
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                                name="daily"
                                                                                value={values.daily!==''?values.daily:RentDaily}
                                                                                error={Boolean(touched.daily && errors.daily)}
                                                                            />
                                                                            {touched.daily && errors.daily && (
                                                                                <FormHelperText error id="standard-weight-helper-text-daily-login">
                                                                                    {errors.daily}
                                                                                </FormHelperText>
                                                                            )}
                                                                        </Grid>
                                                                        <Grid item lg={3} md={6} sm={12}>
                                                                            <Typography sx={{ mb: 1 }}>{t('Weekly')}<span style={{color: 'red'}}>*</span> ({t('7 days')})</Typography>
                                                                            <TextField
                                                                                placeholder={t('Weekly')}
                                                                                fullWidth
                                                                                type="number"
                                                                                onBlur={handleBlur}
                                                                                onChange={handleChange}
                                                                                name="weekly"
                                                                                InputProps={{ inputProps: { min: 0} }}
                                                                                value={values.weekly!==''?values.weekly:RentWeekly}
                                                                                error={Boolean(touched.weekly && errors.weekly)}
                                                                            />
                                                                            {touched.weekly && errors.weekly && (
                                                                                <FormHelperText error id="standard-weight-helper-text-weekly-login">
                                                                                    {errors.weekly}
                                                                                </FormHelperText>
                                                                            )}
                                                                        </Grid>
                                                                        <Grid item lg={3} md={6} sm={12}>
                                                                            <Typography sx={{ mb: 1 }}>{t('Monthly')}<span style={{color: 'red'}}>*</span> ({t('30 days')})</Typography>
                                                                            <TextField
                                                                                placeholder={t('Monthly')}
                                                                                fullWidth
                                                                                type="number"
                                                                                onBlur={handleBlur}
                                                                                InputProps={{ inputProps: { min: 0} }}
                                                                                onChange={handleChange}
                                                                                name="monthly"
                                                                                value={values.monthly!==''?values.monthly:RentMonthly}
                                                                                error={Boolean(touched.monthly && errors.monthly)}
                                                                            />
                                                                            {touched.monthly && errors.monthly && (
                                                                                <FormHelperText error id="standard-weight-helper-text-monthly-login">
                                                                                    {errors.monthly}
                                                                                </FormHelperText>
                                                                            )}
                                                                        </Grid>
                                                                        <Grid item lg={12} md={12} sm={12}>
                                                                            <Typography sx={{ color: 'gray' }}>{t('PLEASE NOTE: Customer will always receive the best rate for example: if the weekly rate is cheaper than the duration multiplied by the day rate the weekly day applies')}</Typography>
                                                                        </Grid>
                                                                    </>
                                                                ):(
                                                                    <></>
                                                                )
                                                            }

                                                        </Grid>
                                                    </Grid>
                                                    {
                                                        Service.tag !== process.env.REACT_APP_EQUIPMENT_RENT && values.is_price_display?(
                                                            <Grid item lg={12} md={12} sm={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item lg={12} md={12} sm={12}>
                                                                        <Typography variant="h4">{t('Cost')}</Typography>
                                                                        <Divider sx={{ mt: 2 }} />
                                                                    </Grid>
                                                                    <Grid item lg={6} md={6} sm={12}>
                                                                        <Typography sx={{ mb: 1 }}>{t('Cost Per Unit')}</Typography>
                                                                        <TextField
                                                                            placeholder="Unit"
                                                                            type="number"
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            name="cost_per_unit"
                                                                            value={values.cost_per_unit}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item lg={6} md={6} sm={12}>
                                                                        <Typography sx={{ mb: 1 }}>{t('Margin')}</Typography>
                                                                        <TextField
                                                                            placeholder={t('Margin')}
                                                                            fullWidth
                                                                            disabled
                                                                            value={
                                                                                values.cost_per_unit!==''?(values.unit_price - values.cost_per_unit?
                                                                                    values.unit_price - values.cost_per_unit:''):''
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ):
                                                            (
                                                                <></>
                                                            )
                                                    }
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12} sm={12}>
                                                                <Typography variant="h4">{t('Search Engine Optimization (SEO)')}</Typography>
                                                                <Divider sx={{ mt: 2 }} />
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12}>
                                                                <Typography color="blue">{values.name_en}</Typography>
                                                                <Typography color="green">{settings.customer_product_url+'1/'+(values.name_en).replace(/\s/g,'-')}</Typography>
                                                                <div dangerouslySetInnerHTML={{__html: values.desc_en}}></div>
                                                                <Typography sx={{ my: 1 }}>{t('Meta Description')}</Typography>
                                                                <TextField
                                                                    fullWidth
                                                                    multiline
                                                                    rows={4}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="seo_desc_en"
                                                                    value={values.seo_desc_en!==''?values.seo_desc_en.replace(/<\/?[^>]+(>|$)/g, ""):values.desc_en.replace(/<\/?[^>]+(>|$)/g, "")}
                                                                    error={Boolean(touched.seo_desc_en && errors.seo_desc_en)}
                                                                />
                                                                <Typography sx={{ my: 1 }}>{t('Meta Keywords')}</Typography>
                                                                <TextField
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="seo_keywords"
                                                                    value={values.seo_keywords!==''?values.seo_keywords:values.name_en.replace(' ',', ')}
                                                                    error={Boolean(touched.seo_keywords && errors.seo_keywords)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Card sx={{ minWidth: 250 }}>
                                            <CardContent>
                                                <Grid container spacing={gridSpacing}>
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12} sm={12}>
                                                                <Typography variant="h4">{t('Item Info')}</Typography>
                                                                <Divider sx={{ mt: 2 }} />
                                                            </Grid>
                                                            <Grid item lg={4} md={6} sm={12}>
                                                                <Typography sx={{ mb: 1 }}>{t('SKU')}</Typography>
                                                                <TextField
                                                                    placeholder={t('SKU')}
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="sku"
                                                                    value={values.sku}
                                                                    error={Boolean(touched.sku && errors.sku)}
                                                                />
                                                                {touched.sku && errors.sku && (
                                                                    <FormHelperText error id="standard-weight-helper-text-sku-login">
                                                                        {errors.sku}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={4} md={6} sm={12}>
                                                                <Typography sx={{ mb: 1 }}>{t('Item Code')}</Typography>
                                                                <TextField
                                                                    placeholder={t('Item Code')}
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="item_code"
                                                                    value={values.item_code}
                                                                    error={Boolean(touched.item_code && errors.item_code)}
                                                                />
                                                                {touched.item_code && errors.item_code && (
                                                                    <FormHelperText error id="standard-weight-helper-text-item_code-login">
                                                                        {errors.item_code}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>

                                                            {/*<Grid item lg={3} md={6} sm={12}>*/}
                                                            {/*    <Typography sx={{ mb: 1 }}>{t('Stock')}</Typography>*/}
                                                            {/*    <TextField*/}
                                                            {/*        placeholder={t('Stock')}*/}
                                                            {/*        fullWidth*/}
                                                            {/*        type="number"*/}
                                                            {/*        onBlur={handleBlur}*/}
                                                            {/*        onChange={handleChange}*/}
                                                            {/*        name="stock"*/}
                                                            {/*        value={values.stock}*/}
                                                            {/*    />*/}
                                                            {/*</Grid>*/}
                                                            <Grid item lg={4} md={6} sm={12}>
                                                                <Typography sx={{ mb: 1 }}>{t('Barcode')}</Typography>
                                                                <TextField
                                                                    placeholder={t('Barcode')}
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="barcode"
                                                                    value={values.barcode}
                                                                    error={Boolean(touched.barcode && errors.barcode)}
                                                                />
                                                                {touched.barcode && errors.barcode && (
                                                                    <FormHelperText error id="standard-weight-helper-text-barcode-login">
                                                                        {errors.barcode}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <FieldArray name="media_links">
                                                        {({ push, remove }) => (
                                                            <React.Fragment>
                                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                    <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
                                                                        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>{t('Media')}</Typography>
                                                                        {
                                                                            values.media_links.length<5?(
                                                                                <Button size="small"
                                                                                        onClick={() => push(emptyMediaList_arr)}
                                                                                        sx={{
                                                                                            backgroundColor: '#0e3152',
                                                                                            color: 'white',
                                                                                            mt: 0.7,
                                                                                            '&:hover': {
                                                                                                backgroundColor: '#7b8ea0'
                                                                                            }
                                                                                        }}
                                                                                >
                                                                                    {t('Add Media')}
                                                                                </Button>
                                                                            ):(
                                                                                <></>
                                                                            )
                                                                        }
                                                                    </Box>
                                                                </Grid>

                                                                {values.media_links.map((_, index) => (
                                                                    <Grid
                                                                        container
                                                                        item
                                                                        key={index}
                                                                        spacing={2}
                                                                    >
                                                                        <Grid item lg={12} md={12} xs={12}>
                                                                            <Typography sx={{ mb: 1 }}>{t('Video URL')} {index+1}</Typography>
                                                                            <div class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root muiltr-wb57ya-MuiFormControl-root-MuiTextField-root">
                                                                                <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary Mui-error MuiInputBase-fullWidth MuiInputBase-formControl muiltr-8skbml-MuiInputBase-root-MuiOutlinedInput-root">
                                                                                    <Field style={{border: '1px solid #bfc0c2'}}
                                                                                           placeholder={t('Video URL')+' '+(index+1)}
                                                                                           fullWidth
                                                                                           class="MuiInputBase-input MuiOutlinedInput-input muiltr-9lyone-MuiInputBase-input-MuiOutlinedInput-input"
                                                                                           name={`media_links.${index}.url`}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>
                                                    <FieldArray name="specification_arr">
                                                        {({ push, remove }) => (
                                                            <React.Fragment>
                                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                    <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
                                                                        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>{t('Specification')}</Typography>
                                                                        <Button size="small"
                                                                                onClick={() => push(emptySpecification_arr)}
                                                                                sx={{
                                                                                    backgroundColor: '#0e3152',
                                                                                    color: 'white',
                                                                                    mt: 0.7,
                                                                                    '&:hover': {
                                                                                        backgroundColor: '#7b8ea0'
                                                                                    }
                                                                                }}
                                                                        >
                                                                            {t('Add Row')}
                                                                        </Button>
                                                                    </Box>
                                                                </Grid>

                                                                {values.specification_arr.map((_, index) => (
                                                                    <Grid
                                                                        container
                                                                        item
                                                                        key={index}
                                                                        spacing={2}
                                                                    >
                                                                        <Grid item lg={3.3} md={3.3} sm={2.5} xs={12}>
                                                                            <Typography sx={{ mb: 1 }}>{t('Title English')}</Typography>
                                                                            <div className="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root muiltr-wb57ya-MuiFormControl-root-MuiTextField-root">
                                                                                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary Mui-error MuiInputBase-fullWidth MuiInputBase-formControl muiltr-8skbml-MuiInputBase-root-MuiOutlinedInput-root">
                                                                                    <Field style={{border: '1px solid #bfc0c2', width: 'auto'}}
                                                                                           placeholder={t('Title English')}

                                                                                           className="MuiInputBase-input MuiOutlinedInput-input muiltr-9lyone-MuiInputBase-input-MuiOutlinedInput-input"
                                                                                           name={`specification_arr.${index}.key_en`}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item lg={3.3} md={3.3} sm={2.5} xs={12}>
                                                                            <Typography sx={{ mb: 1 }}>{t('Title Arabic')}</Typography>
                                                                            <div className="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root muiltr-wb57ya-MuiFormControl-root-MuiTextField-root">
                                                                                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary Mui-error MuiInputBase-fullWidth MuiInputBase-formControl muiltr-8skbml-MuiInputBase-root-MuiOutlinedInput-root">
                                                                                    <Field style={{border: '1px solid #bfc0c2', width: 'auto'}}
                                                                                           placeholder={t('Title Arabic')}
                                                                                           fullWidth
                                                                                           className="MuiInputBase-input MuiOutlinedInput-input muiltr-9lyone-MuiInputBase-input-MuiOutlinedInput-input"
                                                                                           name={`specification_arr.${index}.key_ar`}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item lg={3.3} md={3.3} sm={2.5} xs={12}>
                                                                            <Typography sx={{ mb: 1 }}>{t('Value')}</Typography>
                                                                            <div className="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root muiltr-wb57ya-MuiFormControl-root-MuiTextField-root">
                                                                                <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary Mui-error MuiInputBase-fullWidth MuiInputBase-formControl muiltr-8skbml-MuiInputBase-root-MuiOutlinedInput-root">
                                                                                    <Field style={{border: '1px solid #bfc0c2', width: 'auto'}}
                                                                                           placeholder={t('Value')}
                                                                                           fullWidth
                                                                                           className="MuiInputBase-input MuiOutlinedInput-input muiltr-9lyone-MuiInputBase-input-MuiOutlinedInput-input"
                                                                                           name={`specification_arr.${index}.value`}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                        {
                                                                            index>0?(
                                                                                <Grid item lg={2} md={2} xs={12}>
                                                                                    <Typography sx={{ mb: 1 }}>{t('Action')}</Typography>
                                                                                    <Button
                                                                                        size="small" fullWidth
                                                                                        onClick={() => remove(index)}
                                                                                        sx={{
                                                                                            backgroundColor: '#cd0101',
                                                                                            color: 'white',
                                                                                            mt: 0.7,
                                                                                            '&:hover': {
                                                                                                backgroundColor: '#e79898'
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {t('Remove')}
                                                                                    </Button>
                                                                                </Grid>
                                                                            ):(
                                                                                <></>
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                ))}
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>
                                                    {
                                                        Service.tag !== process.env.REACT_APP_EQUIPMENT_RENT?(
                                                            <Grid item lg={12} md={12} sm={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item lg={12} md={12} sm={12}>
                                                                        <Typography variant="h4">{t('Offer')}</Typography>
                                                                        <Divider sx={{ mt: 2 }} />
                                                                    </Grid>
                                                                    <Grid item lg={4} md={6} sm={12}>
                                                                        <Typography sx={{ mb: 1 }}>{t('Offer')}</Typography>
                                                                        <TextField
                                                                            InputProps={{
                                                                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                                                                            }}
                                                                            placeholder={t('Offer')}
                                                                            type="number"
                                                                            fullWidth
                                                                            onBlur={handleBlur}
                                                                            onChange={handleChange}
                                                                            name="offer"
                                                                            value={values.offer}
                                                                            error={Boolean(touched.offer && errors.offer)}
                                                                        />
                                                                        {touched.offer && errors.offer && (
                                                                            <FormHelperText error id="standard-weight-helper-text-offer-login">
                                                                                {errors.offer}
                                                                            </FormHelperText>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item lg={4} md={6} sm={12}>
                                                                        <Typography sx={{ mb: 1 }}>{t('Price After Offer')}</Typography>
                                                                        <TextField
                                                                            value={
                                                                                values.unit_price - (values.unit_price * values.offer) / 100
                                                                                    ? (values.unit_price - (values.unit_price * values.offer) / 100).toFixed(2)
                                                                                    : 0
                                                                            }
                                                                            disabled
                                                                            placeholder={t('Price After Offer')}
                                                                            fullWidth
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ):
                                                            (
                                                                <></>
                                                            )
                                                    }

                                                    {/*<Grid item lg={12} md={12} sm={12}>*/}
                                                    {/*    <LoadingButton*/}
                                                    {/*        type="submit"*/}
                                                    {/*        variant="contained"*/}
                                                    {/*        color="secondary"*/}
                                                    {/*        onClick={() => setVariationActive(false)}*/}
                                                    {/*        loading={ButtonLoading}*/}
                                                    {/*        loadingPosition="center"*/}
                                                    {/*    >*/}
                                                    {/*        {t('Update Product')}*/}
                                                    {/*    </LoadingButton>*/}
                                                    {/*    <LoadingButton*/}
                                                    {/*        type="submit"*/}
                                                    {/*        variant="outlined"*/}
                                                    {/*        color="secondary"*/}
                                                    {/*        sx={{mx: 2}}*/}
                                                    {/*        onClick={() => setVariationActive(true)}*/}
                                                    {/*        loading={ButtonLoading}*/}
                                                    {/*        loadingPosition="center"*/}
                                                    {/*    >*/}
                                                    {/*        {t('Add variation')}*/}
                                                    {/*    </LoadingButton>*/}
                                                    {/*</Grid>*/}
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>

                                <Box sx={{position: 'sticky', bottom: '10px', width: '100%' , left: 0,right: '0'}}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={12} md={12} sm={12} sx={{textAlign: 'right'}}>
                                            <Card elevation={2} sx={{backgroundColor: '#0e3152'}}>
                                                <CardContent>
                                                    {
                                                        !otherVariationActive?(
                                                            <LoadingButton
                                                                type="submit"
                                                                variant="contained"
                                                                color="warning"
                                                                sx={{
                                                                    backgroundColor: '#ffd13d'
                                                                }}
                                                                onClick={() => setVariationActive(false)}
                                                                loading={ButtonLoading}
                                                                loadingPosition="center"
                                                            >
                                                                {t('Update Product')}
                                                            </LoadingButton>
                                                        ):(
                                                            <></>
                                                        )
                                                    }
                                                    {/*<LoadingButton*/}
                                                    {/*    type="submit"*/}
                                                    {/*    variant="outlined"*/}
                                                    {/*    color="warning"*/}
                                                    {/*    sx={{mx: 2, borderColor: '#ffd13d'}}*/}
                                                    {/*    onClick={() => setVariationActive(true)}*/}
                                                    {/*    loading={ButtonLoading}*/}
                                                    {/*    loadingPosition="center"*/}
                                                    {/*>*/}
                                                    {/*    {t('Add variation')}*/}
                                                    {/*</LoadingButton>*/}
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        )}
                    </Formik>
                )}
            </Grid>
            <Dialog open={variationOpen} onClose={handleVariationClose} fullWidth maxWidth="md" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" variant="f4">
                    {t('Add Variation')}
                </DialogTitle>
                <Formik
                    initialValues={{
                        variation_name_en: '',
                        variation_name_ar: '',
                        product_id: ProductId,
                        parent_id: ParentId,
                    }}
                    validationSchema={Yup.object().shape({
                        variation_name_en: Yup.string().max(255).required(t('English variation name is required')),
                        variation_name_ar: Yup.string().max(255).required(t('Arabic variation name is required')),
                    })}
                    onSubmit={async (values) => {

                        handleProductSave(values);

                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <DialogContent>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Typography sx={{ mb: 1 }}>{t('Variation Name English')}</Typography>
                                        <TextField
                                            placeholder={t('Variation Name English')}
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name="variation_name_en"
                                            value={values.variation_name_en}
                                            error={Boolean(touched.variation_name_en && errors.variation_name_en)}
                                        />
                                        {touched.variation_name_en && errors.variation_name_en && (
                                            <FormHelperText error id="standard-weight-helper-text-variation_name_en-login">
                                                {errors.variation_name_en}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Typography sx={{ mb: 1 }}>{t('Variation Name Arabic')}</Typography>
                                        <TextField
                                            placeholder={t('Variation Name Arabic')}
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name="variation_name_ar"
                                            value={values.variation_name_ar}
                                            error={Boolean(touched.variation_name_ar && errors.variation_name_ar)}
                                        />
                                        {touched.variation_name_ar && errors.variation_name_ar && (
                                            <FormHelperText error id="standard-weight-helper-text-variation_name_ar-login">
                                                {errors.variation_name_ar}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleVariationClose} variant="outlined" color="error">
                                    {t('Cancel')}
                                </Button>
                                <LoadingButton type="submit" variant="outlined" color="secondary" sx={{mx: 2}}
                                               onClick={() => setOtherVariationActive(true)}
                                               loading={ButtonLoading}
                                               loadingPosition="center">
                                    {t('Add and add another')}
                                </LoadingButton>
                                <LoadingButton type="submit" variant="contained" color="secondary"
                                               onClick={() => setOtherVariationActive(false)}
                                               loading={ButtonLoading}
                                               loadingPosition="center">
                                    {t('Add and close')}
                                </LoadingButton>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>
            <Dialog open={deleteOpen} fullWidth maxWidth="sm" onClose={handleDeleteClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" variant="f4">
                    {t('Delete')}
                </DialogTitle>

                <DialogContent>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                    >
                        <Grid item lg={12} md={12} sm={12}>
                            <Typography>{t('Are you sure you want to delete?')}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} variant="outlined" color="error">
                        {t('Cancel')}
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() =>
                        {
                            const formData = {
                                product_id: variantProductId,
                            };
                            deleteVariationProducts(formData)
                                .then((res) => {
                                    const response = res.data;
                                    if(response.code===1){
                                        toast.success(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                                        setProduct(response.data);
                                        setCategoryId(response.data.category_id);
                                        setSubCategoryId(response.data.sub_category_id);
                                        setSubSubCategoryId(response.data.sub_sub_category_id);
                                        setImages(response.data.media);
                                        setFormState({
                                            patterns: response.data.pattern
                                        });
                                        // setFormState(response.data.pattern)
                                        setUnitType(response.data.unit_type);
                                        if(response.data.service_tag === process.env.REACT_APP_EQUIPMENT_RENT){
                                            const rent_rates = response.data.rent_rates
                                            for(const rent of rent_rates){
                                                if(rent.rate_tag === process.env.REACT_APP_RENT_HOURLY){
                                                    setRentHourly(rent.rate_value);
                                                }
                                                if(rent.rate_tag === process.env.REACT_APP_RENT_DAILY){
                                                    setRentDaily(rent.rate_value);
                                                }
                                                if(rent.rate_tag === process.env.REACT_APP_RENT_WEEKLY){
                                                    setRentWeekly(rent.rate_value);
                                                }
                                                if(rent.rate_tag === process.env.REACT_APP_RENT_MONTHLY){
                                                    setRentMonthly(rent.rate_value);
                                                }
                                            }
                                        }

                                        setDeleteOpen(false);
                                    }
                                    else{
                                        toast.error(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                                    }
                                })
                                .catch((err) => {
                                    if (err?.response?.status === 401) {
                                        toast.error(err.response?.data?.error, toastOptions);
                                    }
                                })
                        }
                        }
                    >
                        {t('Delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default EditProduct;
