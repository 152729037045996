import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {Box, Divider, Grid, Stack, Typography, useMediaQuery} from '@mui/material';

// project imports
import AuthWrapper1 from './AuthWrapper1';
import AuthCardWrapper from './AuthCardWrapper';
import AuthLogin from './auth-forms/AuthLogin';
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import Google2F from "./google2f";
import Google2FAComponent from "./google2f";
import uk_lang from "../../assets/images/uk.png";
import sa_lang from "../../assets/images/ksa.png";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {SET_FONT_FAMILY} from "../../store/actions";

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const Google2FA = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const changeLanguage = value => {
        i18n.changeLanguage(value);
        if(value==='ar') {
            document.dir = 'rtl'
            document.body.setAttribute("dir", "rtl");
            document.querySelector("html").setAttribute("lang", 'ar')
            dispatch({ type: SET_FONT_FAMILY, font: 'Alexandria'});
        }
        else{
            document.dir = 'ltr'
            document.body.setAttribute("dir", "ltr");
            document.querySelector("html").setAttribute("lang", 'en')
            dispatch({ type: SET_FONT_FAMILY, font: 'Karla'});
        }
    };

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                {
                                    i18n.language==='ar'?(
                                        <Box onClick={() => changeLanguage(i18n.language==='ar'?'en':'ar')}
                                             component="img"
                                             sx={{
                                                 width: 40,
                                                 maxHeight: { xs: 233, md: 167 },
                                                 maxWidth: { xs: 350, md: 250 },
                                             }}
                                             src={uk_lang}
                                        />
                                    ):(
                                        <Box onClick={() => changeLanguage(i18n.language==='ar'?'en':'ar')}
                                             component="img"
                                             sx={{
                                                 width: 40,
                                                 maxHeight: { xs: 233, md: 167 },
                                                 maxWidth: { xs: 350, md: 250 },
                                             }}
                                             src={sa_lang}
                                        />
                                    )
                                }
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Link to="#">
                                            <Logo isAuth={true} />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant={matchDownSM ? 'h3' : 'h2'}
                                                    >
                                                        Hi, Welcome Back
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Google2FAComponent />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item container direction="column" alignItems="center" xs={12}>
                                            <Typography component={Link} to="/login" onClick={() => {
                                                let i18nextLng = localStorage.getItem('i18nextLng');
                                                localStorage.clear();
                                                localStorage.setItem('i18nextLng', i18nextLng);
                                            }} variant="subtitle1" sx={{ textDecoration: 'none' }}>
                                                Back to Login
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default Google2FA;
