import React, { useState, useContext, useEffect } from 'react';
import {
    Grid,
    Typography,
    Button,
    Divider,
    TextField,
    InputAdornment,
    FormHelperText,
    MenuItem,
    Select as MSelect,
    IconButton,
    Box
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useNavigate, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import DropZone from 'ui-component/DropZone';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import ToastContext from 'context/ToastContext';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Select from '../Select';
import Autocomplete from '@mui/material/Autocomplete';
import AWS from 'aws-sdk';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';
import {
    getAllCategoriesListbyService,
    createServices,
    getallUnit,
    getCompanyCategories,
    getCompanyServiceById,
    updateServices, getalladminAppSetting
} from 'api/api';
import {useTranslation} from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import imageCompression from "browser-image-compression";
import moment from "moment/moment";

const EditService = () => {
    let currentUser = JSON.parse(localStorage.getItem('auth_user')).user;
    let service_id = localStorage.getItem('serv-id');
    let company_service_id = localStorage.getItem('comp-serv-id');
    const toastOptions = useContext(ToastContext);
    let navigate = useNavigate();
    let service = useParams();
    const [ProductImage, setProductImage] = useState();
    const [serviceCategory, setServiceCategory] = useState([]);
    const [companyService, setCompanyService] = useState([]);
    const [UnitsData, setUnitsData] = useState([]);
    const [ButtonLoading, setButtonLoading] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [settings, setSettings] = useState();
    const { t, i18n } = useTranslation();
    const [subCategoryIds, setSubCategoryIds] = React.useState([]);
    const [subSubCategoryIds, setSubSubCategoryIds] = React.useState([]);
    const [Image, setImage] = React.useState();

    useEffect(() => {
        getCompanyCategories({ service_id: service_id }).then((res) => {
            setServiceCategory(res.data.data);
        });
        getCompanyServiceById({ company_service_id: company_service_id}).then((res) => {

            const response = res.data;
            if(response.code===1){
                setCompanyService(res.data.data);
                let sub_category_ids = [];
                let sub_sub_category_ids = [];
                response.data.sub_category_id.map((e) => sub_category_ids.push(e.sub_category_id));
                response.data.sub_sub_category_id.map((e) => sub_sub_category_ids.push(e.sub_sub_category_id));
                setSubCategoryIds(sub_category_ids);
                setSubSubCategoryIds(sub_sub_category_ids);
                setImage(response.data.service_image)
                setLoading(false);
            }
            else{
                setLoading(false);
                toast.error(i18n.language==='ar'?response.ar_message:response.en_message);
            }
        });

        getalladminAppSetting().then((res) => {
            setSettings(res.data.data);
        });
    }, []);

    const [uploadLink, setuploadLink] = useState();

    const handleFileUpload = async (file) => {
        try {
            AWS.config.update({
                accessKeyId: settings.aws_access_key,
                secretAccessKey: settings.aws_secret_key,
                region: settings.aws_region
            });

            const s3 = new AWS.S3();
            const options = {
                maxSizeMB: 1,
            }
            file[0] = await imageCompression(file[0], options);
            let extension = file[0].name.split('.').pop();
            const params = {
                ACL: 'public-read',
                Bucket: settings.aws_bucket_name,
                Key: `omran/company_service/${Date.parse(new Date())}.${extension}`,
                Body: file[0]
            };

            const response = await s3.upload(params).promise();
            setuploadLink(response.Location);
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    const handleSubCategoryChange = (event) => {
        const {
            target: { value },
        } = event;
        setSubCategoryIds(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleSubSubCategoryChange = (event) => {
        const {
            target: { value },
        } = event;
        setSubSubCategoryIds(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12}>
                {isLoading ? (
                    <SkeletonEarningCard />
                ) : (
                    <Card sx={{ minWidth: 250, cursor: 'pointer' }} elevation={2}>
                        <CardContent>
                            <Grid container spacing={gridSpacing}>
                                <Grid item lg={12}>
                                    <Box sx={{display:'flex', justifyContent: 'space-between'}}>
                                        <Box>
                                            <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{t('Service')}</Typography>
                                        </Box>
                                        <Box>
                                            <Button
                                                onClick={() => navigate(-1)}
                                                variant="contained"
                                                color="secondary"
                                                sx={{
                                                    mt: 0.7,
                                                }}
                                            >
                                                {t('Back')}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
                {isLoading ? (
                    <SkeletonEarningCard />
                ) : (
                    <Formik
                        initialValues={{
                            desc_en: companyService.desc_en,
                            desc_ar: companyService.desc_ar,
                            category: companyService.category_id,
                            company_service_id: companyService._id,
                            sub_category: '',
                            sub_sub_category: '',
                            service_id: service_id,
                            user_id: companyService?.user_id,
                            company_id: companyService?.company_id,
                            service_image: companyService.service_image,
                            app_platform: process.env.REACT_APP_PLATFORM,
                            logs: {
                                user: currentUser,
                                time: moment().format(),
                                lat: localStorage.getItem('lat'),
                                lng: localStorage.getItem('lng'),
                            }
                        }}
                        validationSchema={Yup.object().shape({
                            desc_en: Yup.string().max(255).required(t('Description English is required')),
                            desc_ar: Yup.string().max(255).required(t('Description Arabic is required')),
                        })}
                        onSubmit={(values) => {
                            values.service_image = uploadLink?uploadLink:Image;
                            values.category_id = values.category
                            values.sub_category_id = subCategoryIds
                            values.sub_sub_category_id = subSubCategoryIds


                            if (values?.service_image === '') {
                                toast.error(t('Image is required'), toastOptions);
                            } else {
                                setButtonLoading(true);
                                updateServices(values)
                                    .then((res) => {
                                        const response = res.data;
                                        if(response.code===1){
                                            toast.success(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                                            setButtonLoading(false);
                                            localStorage.removeItem('serv-id');
                                            localStorage.removeItem('comp-serv-id');
                                            navigate(`/company-services`);
                                        }
                                        else{
                                            setButtonLoading(false);
                                            toast.error(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                                        }
                                    })
                                    .catch((err) => {
                                        if (err?.response?.status === 401) {
                                            setButtonLoading(false);
                                            toast.error(err.response?.data?.error, toastOptions);
                                        }
                                    });
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container
                                      justifyContent="center"
                                      alignItems="center" spacing={gridSpacing}>
                                    <Grid item lg={8} md={8} sm={12}>
                                        <Card sx={{ minWidth: 250 }}>
                                            <CardContent>
                                                <Grid container spacing={gridSpacing}>
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12}>
                                                                <Typography variant="h4">{t('Category')}</Typography>
                                                                <Divider sx={{ mt: 2 }} />
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={
                                                                    values.category === ''
                                                                        ? 12
                                                                        : values.category !== '' && subCategoryIds.length === 0
                                                                        ? 6
                                                                        : 4
                                                                }
                                                                md={4}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <Typography sx={{ mb: 1 }}>{t('Select Category')}</Typography>
                                                                <Select
                                                                    size="medium"
                                                                    name="category"
                                                                    onChange={handleChange}
                                                                    data={values.category}
                                                                    type="text"
                                                                    onBlur={handleBlur}
                                                                    error={Boolean(touched.category && errors.category)}
                                                                    defaultdata
                                                                    options={serviceCategory}
                                                                />
                                                                {touched.category && errors.category && (
                                                                    <FormHelperText error id="standard-weight-helper-text-category-login">
                                                                        {errors.category}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                            {values?.category !== '' ? (
                                                                <Grid
                                                                    item
                                                                    lg={subCategoryIds.length === 0 ? 6 : 4}
                                                                    md={subCategoryIds.length === 0 ? 6 : 4}
                                                                    sm={12}
                                                                    xs={12}
                                                                >
                                                                    <Typography sx={{ mb: 1 }}>{t(('Select Sub Category'))}</Typography>
                                                                    <MSelect
                                                                        size="medium"
                                                                        multiple
                                                                        fullWidth
                                                                        id="demo-multiple-name"
                                                                        onChange={handleSubCategoryChange}
                                                                        value={subCategoryIds}
                                                                    >
                                                                        {
                                                                            serviceCategory
                                                                                ?.find((e) => e._id === values?.category)
                                                                                ?.sub_category.map((sub) => (
                                                                                    <MenuItem
                                                                                        key={sub._id}
                                                                                        value={sub._id}>
                                                                                        {i18n.language==='ar'?sub.title_ar:sub.title_en}
                                                                                    </MenuItem>
                                                                            ))
                                                                        }
                                                                    </MSelect>
                                                                </Grid>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {subCategoryIds.length>0 ? (
                                                                serviceCategory
                                                                    ?.find((e) => e._id === values.category)
                                                                    ?.sub_category?.find((e) => subCategoryIds.includes(e?._id))
                                                                    ?.sub_sub_category?.length > 0 ? (
                                                                    <Grid item lg={4} md={4} sm={12} xs={12}>
                                                                        <Typography sx={{ mb: 1 }}>{t('Select Sub Sub Category')}</Typography>
                                                                        <MSelect
                                                                            size="medium"
                                                                            multiple
                                                                            fullWidth
                                                                            id="demo-multiple-name"
                                                                            onChange={handleSubSubCategoryChange}
                                                                            value={subSubCategoryIds}
                                                                        >
                                                                            {
                                                                                serviceCategory
                                                                                    ?.find((e) => e._id === values?.category)
                                                                                    ?.sub_category?.map(
                                                                                    (e) => {
                                                                                        if(subCategoryIds.includes(e?._id)){
                                                                                            return (
                                                                                                e.sub_sub_category.map((sub) => {
                                                                                                    return (<MenuItem
                                                                                                        key={sub._id}
                                                                                                        value={sub._id}>
                                                                                                        {i18n.language==='ar'?sub.title_ar:sub.title_en}
                                                                                                    </MenuItem>)
                                                                                                })
                                                                                            )
                                                                                        }
                                                                                    }
                                                                                )
                                                                            }
                                                                        </MSelect>
                                                                    </Grid>
                                                                ) : (
                                                                    ''
                                                                )
                                                            ) : (
                                                                ''
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12}>
                                                                <Typography variant="h4">{t('Description')}</Typography>
                                                                <Divider sx={{ mt: 2 }} />
                                                            </Grid>
                                                            <Grid item lg={6} md={6}>
                                                                <Typography sx={{ mb: 1 }}>{t('Description English')}</Typography>
                                                                {
                                                                    i18n.language==='ar'?(
                                                                        <CKEditor
                                                                            id='ar-lang'
                                                                            editor={ClassicEditor}
                                                                            config={{language: 'ar'}}
                                                                            data={values.desc_en}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                console.log(data);
                                                                                setFieldValue('desc_en', data);
                                                                            }}
                                                                        />
                                                                    ):(
                                                                        <CKEditor
                                                                            id='en-lang'
                                                                            editor={ClassicEditor}
                                                                            config={{language: 'en'}}
                                                                            data={values.desc_en}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                console.log(data);
                                                                                setFieldValue('desc_en', data);
                                                                            }}
                                                                        />
                                                                    )
                                                                }
                                                                {touched.desc_en && errors.desc_en && (
                                                                    <FormHelperText error id="standard-weight-helper-text-desc_en-login">
                                                                        {errors.desc_en}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={6} md={6}>
                                                                <Typography sx={{ mb: 1 }}>{t('Description Arabic')}</Typography>
                                                                {
                                                                    i18n.language==='ar'?(
                                                                        <CKEditor
                                                                            id='ar-lang'
                                                                            config={{language: 'ar'}}
                                                                            editor={ClassicEditor}
                                                                            data={values.desc_ar}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                console.log(data);
                                                                                setFieldValue('desc_ar', data);
                                                                            }}
                                                                        />
                                                                    ):(
                                                                        <CKEditor
                                                                            id='en-lang'
                                                                            config={{language: 'en'}}
                                                                            editor={ClassicEditor}
                                                                            data={values.desc_ar}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                console.log(data);
                                                                                setFieldValue('desc_ar', data);
                                                                            }}
                                                                        />
                                                                    )
                                                                }
                                                                {touched.desc_ar && errors.desc_ar && (
                                                                    <FormHelperText error id="standard-weight-helper-text-desc_ar-login">
                                                                        {errors.desc_ar}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12}>
                                                                <Typography variant="h4">{t('Service Image')}</Typography>
                                                                <Divider sx={{ mt: 2, mb: 2 }} />
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                <DropZone key={0} onChange={handleFileUpload} />
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{m: 2}}>
                                                                <Grid container spacing={2}>
                                                                    {
                                                                        Image?( <Grid item lg={3} md={6} sm={12} style={{position: 'relative', display:'flex'}}>
                                                                            <IconButton
                                                                                onClick={(e) => {
                                                                                    setuploadLink('')
                                                                                    setImage('')
                                                                                }}
                                                                                style={{top:'-10px', left: '-10px',position: 'absolute', color: 'red'}} aria-label="delete"  color="danger">
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                            <img src={Image} width={'80px'}/>
                                                                        </Grid>
                                                                        ):(
                                                                            <></>
                                                                        )
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12}>
                                                    <LoadingButton
                                                        type="submit"
                                                        variant="contained"
                                                        color="secondary"
                                                        loading={ButtonLoading}
                                                        sx={{mt: 1}}
                                                        loadingPosition="center"
                                                    >
                                                        {t('Save Service')}
                                                    </LoadingButton>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                )}
            </Grid>
        </Grid>
    );
};


export default EditService;
