import React, { useMemo, useState, useEffect, useContext } from 'react';
import {Button, Card, CardContent, Grid, TextField, Typography, Link, Box, Pagination} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

import ToastContext from 'context/ToastContext';
import {getalladminAppSetting, getAllProjects, getProjectTransactions, readNotification} from 'api/api';
import {useTranslation} from "react-i18next";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {getAllNotification} from "../../store/actions";
import {useDispatch} from 'react-redux';

const ProjectTransactionList = () => {
    const { t, i18n } = useTranslation();
    const toastOptions = useContext(ToastContext);
    const [rows, setrows] = useState([]);
    const [rowsFiltered, setRowsFiltered] = useState([]);
    const [IsActive, setIsActive] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [specialityId, setSpecialityId] = useState('');
    const [filterStatusArr, setFilterStatusArr] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [specialityArr, setSpecialityArr] = useState([]);
    const [appSetting, setAppSetting] = useState();
    const [customerOpen, setCustomerOpen] = useState(false);
    const [customer, setCustomer] = useState();
    const [receivedAmount, setReceivedAmount] = useState(0);
    const [fromDate, setFromDate] = useState(dayjs(Date.now()));
    const [toDate, setToDate] = useState(dayjs(Date.now()));
    const dispatch = useDispatch();


    useEffect(() => {
        getalladminAppSetting().then((res) => {
            setAppSetting(res.data.data);
        });

        callProjectAPI();

        var user_id = JSON.parse(localStorage.getItem('auth_user'))?.user?._id
        readNotification({user_id: user_id, type: 'PROJECTTRX'}).then((res) => {
            dispatch(getAllNotification({user_id: user_id}));
        });
    }, []);

    const columns = useMemo(() => [
        { field: 'transaction_id', headerName: t('Transaction Id'), flex: 2,
            renderCell: ({ row }) => {
                return (row.transaction_id
                );
            }
        },
        {
            field: 'paid_amount',
            headerName: t('Paid Amount'),
            flex: 1,
            renderCell: ({ row }) => {
                return (row.paid_amount);
            }
        },
        {
            field: 'purchase_date',
            headerName: t('Purchase Date'),
            flex: 1,
            renderCell: ({ row }) => {
                return (row.purchase_date);
            }
        },
        {
            field: 'expire_date',
            headerName: t('Expiry Date'),
            flex: 1,
            renderCell: ({ row }) => {
                return (row.expiry_date);
            }
        },
        {
            field: 'user',
            headerName: t('User'),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Button variant="contained" color="secondary" size="small" onClick={() => {
                        setCustomerOpen(true);
                        setCustomer(row);
                    }}>
                        {t('View')}
                    </Button>
                );
            }
        },
        {
            field: 'platform',
            headerName: t('Platform'),
            flex: 1,
            renderCell: ({ row }) => {
                return (row.platform);
            }
        },
        {
            field: 'status',
            headerName: t('Status'),
            flex: 1,
            renderCell: ({ row }) => {
                if(row.status === 'ACTIVE'){
                    return (
                        <Chip
                            label={t(row.status)}
                            sx={{backgroundColor: 'green', color: 'white'}}
                        />
                    );
                }
                else{
                    return (
                        <Chip
                            label={t(row.status)}
                            sx={{backgroundColor: '#F04438', color: 'white'}}
                        />
                    );
                }
            }
        },
    ]);

    const callProjectAPI = () => {
        getProjectTransactions().then((res) => {
            setrows(res.data.data);
            setRowsFiltered(res.data.data);
            const initialValue = 0;
            setReceivedAmount(res.data.data.reduce((count, e) => count + parseFloat(e.paid_amount), initialValue))
            setIsActive(false);
        });
    }

    const handleCustomerClose = () => {
        setCustomerOpen(false);
    };

    return (
        <>
            {IsActive ? (
                <SkeletonEarningCard />
            ) : (
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item lg={4} md={12} sm={12} xs={12}>
                                <Typography sx={{fontWeight: 'bold',}}>{t('From Date')}: </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker sx={{width: '100%'}}
                                                    value={fromDate}
                                                    onChange={(newDate) => {
                                                        setFromDate(newDate);
                                                        let filteredData = [];
                                                        rows.map((e) => {
                                                            if(e.purchase_date >= newDate.format('YYYY-MM-DD') && e.purchase_date <= toDate.format('YYYY-MM-DD')){
                                                                filteredData.push(e);
                                                            }
                                                        })
                                                        const initialValue = 0;
                                                        setReceivedAmount(filteredData.reduce((count, e) => count + parseFloat(e.paid_amount), initialValue))
                                                        setRowsFiltered(filteredData)
                                                    }} />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item lg={4} md={12} sm={12} xs={12}>
                                <Typography sx={{fontWeight: 'bold',}}>{t('To Date')}: </Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker sx={{width: '100%'}}
                                                    value={toDate}
                                                    onChange={(newDate) => {
                                                        setToDate(newDate);
                                                        let filteredData = [];
                                                        rows.map((e) => {
                                                            if(e.purchase_date >= fromDate.format('YYYY-MM-DD') && e.purchase_date <= newDate.format('YYYY-MM-DD')){
                                                                filteredData.push(e);
                                                            }
                                                        })
                                                        const initialValue = 0;
                                                        setReceivedAmount(filteredData.reduce((count, e) => count + parseFloat(e.paid_amount), initialValue))
                                                        setRowsFiltered(filteredData)

                                                    }}  />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <Typography variant="h3">{t('Project Transactions')}</Typography>
                                    <Typography variant="h3">{t('Total Received')}: {receivedAmount}</Typography>
                                </Box>
                            </Grid>


                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                {' '}
                                <DataGrid
                                    getRowHeight={() => 'auto'}
                                                      getEstimatedRowHeight={() => 200}
                                    disableRowSelectionOnClick
                                    rows={rowsFiltered}
                                    columns={columns}
                                    getRowId={(row) => row._id}
                                    sx={{
                                        border: 0,
                                        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                                            outline: 'none !important'
                                        }
                                    }}
                                />
                            </Grid>

                        </Grid>
                        {
                            customer && (
                                <Dialog open={customerOpen} fullWidth maxWidth="sm" onClose={handleCustomerClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                    <DialogContent>
                                        <Grid container spacing={2}>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <Typography sx={{ mb: 1, fontWeight: 'bold' }}>Customer Info</Typography>
                                                <Grid container spacing={2}>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                                                            <Typography sx={{mb: 1}}>{t('Image')}: </Typography>
                                                            <img
                                                                src={customer?.media ? customer?.media : process.env.REACT_APP_USER_IMG}
                                                                alt="image" width="50"/>

                                                        </Box>
                                                        <Typography sx={{ mb: 1, }}>{t('Name')}: {customer.name}</Typography>
                                                        <Typography sx={{ mb: 1,  }}>{t('Phone')}: &nbsp;
                                                            <Link href={`tel:${customer.phone}`}>
                                                                 {customer.phone}
                                                            </Link>
                                                        </Typography>
                                                        <Typography sx={{ mb: 1,  }}>{t('Email')}: &nbsp;
                                                            <Link href={`mailto:${customer.email}`}>
                                                                 {customer.email}
                                                            </Link>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCustomerClose} variant="contained">
                                            {t('Close')}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            )
                        }
                    </CardContent>
                </Card>
            )}
        </>
    );
};

export default ProjectTransactionList;
