import React, { useMemo, useState, useEffect, useContext } from 'react';
import {Button, Card, CardContent, Grid, TextField, Typography, Link, Box} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import ToastContext from 'context/ToastContext';
import {getAdvertiseRequest, getAllInquiry, readNotification} from 'api/api';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {getAllNotification} from "../../store/actions";
import {useDispatch} from 'react-redux';
const Advertise = () => {
    const { t, i18n } = useTranslation();
    const toastOptions = useContext(ToastContext);
    const [rows, setrows] = useState([]);
    const [IsActive, setIsActive] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [editData, setEditData] = useState();
    const [companyOpen, setCompanyOpen] = useState(false);
    const [company, setCompany] = useState();
    const [customerOpen, setCustomerOpen] = useState(false);
    const [customer, setCustomer] = useState();
    const [fromDate, setFromDate] = useState(dayjs(Date.now()));
    const [toDate, setToDate] = useState(dayjs(Date.now()));
    const [inquiries, setInquiries] = useState();
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClickOpen = (data) => {
        setOpen(true);
        setEditData(data);
    };

    const handleCompanyClose = () => {
        setCompanyOpen(false);
    };
    const handleCustomerClose = () => {
        setCustomerOpen(false);
    };
    useEffect(() => {
        getAdvertiseRequest().then((res) => {
            setrows(res.data.data);
            setIsActive(false);
        });

        var user_id = JSON.parse(localStorage.getItem('auth_user'))?.user?._id
        readNotification({user_id: user_id, type: 'ADVERTISE'}).then((res) => {
            dispatch(getAllNotification({user_id: user_id}));
        });
    }, []);
    const columns = useMemo(() => [
        { field: 'name', headerName: t('Company Name'), flex: 1,
            renderCell: ({ row }) => {
                return (row.company_name);
            }
        },
        {
            field: 'description',
            headerName: t('Description'),
            flex: 1,
            renderCell: ({ row }) => {
                return (row.desc);
            }
        },
        {
            field: 'email',
            headerName: t('Email'),
            flex: 1,
            renderCell: ({ row }) => {
                return (row.email);
            }
        },
        {
            field: 'phone',
            headerName: t('Phone'),
            flex: 1,
            renderCell: ({ row }) => {
                return (row.phone);
            }
        },
        {
            field: 'service',
            headerName: t('Service'),
            flex: 1,
            renderCell: ({ row }) => {
                return (i18n.language==='ar'?row.service_ar:row.service_en);
            }
        },
        {
            field: 'platform',
            headerName: t('Platform'),
            flex: 1,
            renderCell: ({ row }) => {
                return (row.app_platform);
            }
        },
        {
            field: 'date',
            headerName: t('Date'),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    moment(row?.createdAt).format('YYYY-MM-DD hh:mm A')
                );
            }
        },
    ]);
    return (
        <>
            {IsActive ? (
                <SkeletonEarningCard />
            ) : (
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography variant="h3">{t('Advertise Request')}</Typography>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                {' '}
                                <DataGrid
                                    getRowHeight={() => 'auto'}
                                                      getEstimatedRowHeight={() => 200}
                                    disableRowSelectionOnClick
                                    rows={rows}
                                    columns={columns}
                                    getRowId={(row) => row._id}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 10 }
                                        }
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    sx={{
                                        border: 0,
                                        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                                            outline: 'none !important'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )}
            {
                company && (
                    <Dialog open={companyOpen} fullWidth maxWidth="sm" onClose={handleCompanyClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Typography sx={{ mb: 1, fontWeight: 'bold' }}>Company Info</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography sx={{ mb: 1, }}>Name: {company.name}</Typography>
                                            <Typography sx={{ mb: 1,  }}>Phone: {company.phone}</Typography>
                                            <Typography sx={{ mb: 1,  }}>Email: {company.email}</Typography>
                                            <Link variant="secondary" color="secondary" href={
                                                'https://frjarapp.com/company-view/'+company.name.toLowerCase().replaceAll(/[.,\s]/g,'-').replaceAll('/','')
                                            } target="_blank">{t('View Company')}</Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Typography sx={{ mb: 1, fontWeight: 'bold' }}>Provider Info</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography sx={{ mb: 1, }}>Name: {company.provider.name}</Typography>
                                            <Typography sx={{ mb: 1,  }}>Phone: {company.provider.phone}</Typography>
                                            <Typography sx={{ mb: 1,  }}>Email: {company.provider.email}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCompanyClose} variant="contained">
                                {t('Close')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
            {
                customer && (
                    <Dialog open={customerOpen} fullWidth maxWidth="sm" onClose={handleCustomerClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <Typography sx={{ mb: 1, fontWeight: 'bold' }}>Customer Info</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Typography sx={{ mb: 1, }}>Name: {customer.name}</Typography>
                                            <Typography sx={{ mb: 1,  }}>Phone: {customer.phone}</Typography>
                                            <Typography sx={{ mb: 1,  }}>Email: {customer.email}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCustomerClose} variant="contained">
                                {t('Close')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
        </>
    );
};

export default Advertise;
