import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import {SET_FONT_FAMILY, SET_MENU} from "../../../store/actions";

// assets
import { IconMenu2 } from '@tabler/icons';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import uk_lang from 'assets/images/uk.png';
import sa_lang from 'assets/images/ksa.png';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const current = JSON.parse(localStorage.getItem('auth_user'));
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const changeLanguage = value => {


        i18n.changeLanguage(value);
        if(value==='ar') {
            document.dir = 'rtl'
            document.body.setAttribute("dir", "rtl");
            document.querySelector("html").setAttribute("lang", 'ar')
            dispatch({ type: SET_FONT_FAMILY, font: 'Alexandria'});
        }
        else{
            document.dir = 'ltr'
            document.body.setAttribute("dir", "ltr");
            document.querySelector("html").setAttribute("lang", 'en')
            dispatch({ type: SET_FONT_FAMILY, font: 'Karla'});
        }
    };

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1, textAlign: 'center' }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {
                i18n.language==='ar'?(
                    <Box onClick={() => changeLanguage(i18n.language==='ar'?'en':'ar')}
                         component="img"
                         sx={{
                             width: 40,
                             mx:1,
                             maxHeight: { xs: 233, md: 167 },
                             maxWidth: { xs: 350, md: 250 },
                         }}
                         src={uk_lang}
                    />
                ):(
                    <Box onClick={() => changeLanguage(i18n.language==='ar'?'en':'ar')}
                         component="img"
                         sx={{
                             width: 40,
                             mx:1,
                             maxHeight: { xs: 233, md: 167 },
                             maxWidth: { xs: 350, md: 250 },
                         }}
                         src={sa_lang}
                    />
                )
            }
            {/* notification & profile */}
            {/*<NotificationSection />*/}
            <ProfileSection />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
