import React, { useMemo, useState, useEffect, useContext } from 'react';
import {
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Typography,
    Box,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputAdornment,
    Pagination,
    TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody,
    Link
} from '@mui/material';
import AWS from 'aws-sdk';
import {DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import ToastContext from 'context/ToastContext';
import {
    updateuser,
    getAdminBanks,
    addAdminBank,
    deleteAdminBank,
    updateAdminBank,
    getCompanyBanks,
    updateCompanyBank, readNotification, getAllCustomer
} from 'api/api';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import {useTranslation} from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from "@mui/lab/LoadingButton";
import {getAllNotification} from "../../store/actions";
import {useDispatch} from 'react-redux';

const itemPerPage = 30;
const CompanyBank = () => {
    const current = JSON.parse(localStorage.getItem('auth_user'));
    const toastOptions = useContext(ToastContext);
    const [rows, setrows] = useState([]);
    const [IsActive, setIsActive] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [bankOpen, setBankOpen] = useState(false);
    const [bankData, setBankData] = useState();
    const [bankInfoOpen, setBankInfoOpen] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const handleBankInfoClose = () => {
        setBankInfoOpen(false);
        setBankData();
    };

    const handleBankClose = () => {
        setBankOpen(false);
        setBankData();
    };

    useEffect(() => {
        readNotification({user_id: current?.user?._id, type: 'BANK'}).then((res) => {
            dispatch(getAllNotification({user_id: current?.user?._id}));
        });
    }, []);

    useEffect(() => {
        getCompanyBanks({user_id: current?.user?._id, page_number: pageNumber, item_limit: itemPerPage}).then((res) => {
            const response = res.data;
            setIsActive(false);
            if(response.code === 1){
                setrows(res.data.data.bank_arr);
                setTotalCount(res.data.data.total_count);
                setIsActive(false);
            }
            else{
                toast.error(i18n.language==='ar'?response.ar_message:response.en_message);
            }
        }).catch((err) => {
            setIsActive(false);
            if (err?.response?.status === 401) {
                toast.error(err.response?.data?.error, toastOptions);
            }
        });
    }, [pageNumber]);


    const onPageChange = (event, value) => {
        setPageNumber(value);
        window.scrollTo({ top: 0, behavior: "smooth" })
    }

    return (
        <>
            {IsActive ? (
                <SkeletonEarningCard />
            ) : (
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item lg={11} md={11} sm={11} xs={11}>
                                        <Typography variant="h3">{t('Company Bank Accounts')}</Typography>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {' '}
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>{t('Bank Name')}</TableCell>
                                                        <TableCell>{t('Account Holder Name')}</TableCell>
                                                        <TableCell>{t('IBAN')}</TableCell>
                                                        <TableCell>{t('Company')}</TableCell>
                                                        <TableCell>{t('Status')}</TableCell>
                                                        <TableCell>{t('Action')}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rows.map((row) => (
                                                        <TableRow
                                                            key={row._id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                <Typography>{row.bank_name}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>{row.acc_holder_name}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>{row.iban}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Typography>{row.company?.name}</Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    row.is_verified?
                                                                        <Chip label={t('Verified')} color="success" />:<Chip label={t('Not Verified')} color="error" />
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box display="felx" alignItems="center" gap={1}>
                                                                    <Button variant="contained" color="secondary" size="small" onClick={() => {
                                                                        setBankOpen(true);
                                                                        setBankData(row);
                                                                    }}>{t('Update')}</Button>
                                                                    <Button variant="contained" sx={{mx: 1}} color="secondary" size="small" onClick={() => {
                                                                        setBankInfoOpen(true);
                                                                        setBankData(row);
                                                                    }}>{t('View Info')}</Button>
                                                                </Box>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    {
                                        totalCount >itemPerPage ?(
                                            <Grid item lg={12} md={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                    <Pagination count={ Math.ceil(totalCount/itemPerPage)} page={pageNumber} onChange={onPageChange} />
                                                </Box>
                                            </Grid>
                                        ):(
                                            <></>
                                        )
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
            {
                bankData && (
                    <React.Fragment>
                        <Dialog open={bankOpen} onClose={handleBankClose} fullWidth maxWidth="xs" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title" variant="f4">
                                {t('Update Bank')}
                            </DialogTitle>
                            <Formik
                                initialValues={{
                                    user_id: current?.user?._id,
                                    bank_id: bankData?._id,
                                    is_verified: bankData?.is_verified,
                                }}
                                validationSchema={Yup.object().shape({
                                    is_verified: Yup.boolean().required(t('Status is required')),
                                })}
                                onSubmit={async (values) => {
                                    setButtonLoading(true);
                                    updateCompanyBank(values)
                                        .then((res) => {
                                            const response = res.data;
                                            setButtonLoading(false);
                                            if(response.code===1){
                                                toast.success(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                                                setrows(res.data.data);
                                                handleBankClose();
                                            }
                                            else{
                                                toast.error(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                                            }

                                        })
                                        .catch((err) => {
                                            setButtonLoading(false);
                                            if (err?.response?.status === 401) {
                                                toast.error(err.response?.data?.error, toastOptions);
                                            }
                                        });

                                }}
                            >
                                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                    <form noValidate onSubmit={handleSubmit}>
                                        <DialogContent>
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid item lg={12} md={12} xs={12}>
                                                    <Typography sx={{ mb: 1 }}>{t('Status')}</Typography>
                                                    <Select
                                                        fullWidth
                                                        name="is_verified"
                                                        value={values.is_verified}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}>
                                                        <MenuItem key={1} value={true}>{t('Verify')}</MenuItem>
                                                        <MenuItem key={2} value={false}>{t('Un Verify')}</MenuItem>
                                                    </Select>
                                                    {touched.is_verified && errors.is_verified && (
                                                        <FormHelperText error id="standard-weight-helper-text-is_verified-login">
                                                            {errors.is_verified}
                                                        </FormHelperText>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleBankClose} variant="outlined" color="error">
                                                {t('Cancel')}
                                            </Button>
                                            <LoadingButton type="submit" color="secondary" variant="contained" loading={buttonLoading}
                                                           loadingPosition="center">
                                                {t('Update')}
                                            </LoadingButton>
                                        </DialogActions>
                                    </form>
                                )}
                            </Formik>
                        </Dialog>
                        <Dialog open={bankInfoOpen} onClose={handleBankInfoClose} fullWidth maxWidth="xs" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title" variant="f4">
                                {t('Bank Info')}
                            </DialogTitle>
                            <DialogContent>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Bank Name')}</Typography>
                                            <Typography sx={{ mb: 1 }}>{bankData?.bank_name}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Account Holder Name')}</Typography>
                                            <Typography sx={{ mb: 1 }}>{bankData?.account_number}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Account Number')}</Typography>
                                            <Typography sx={{ mb: 1 }}>{bankData?.acc_holder_name}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('IBAN')}</Typography>
                                            <Typography sx={{ mb: 1 }}>{bankData?.iban}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{my:1}}>
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Attachment')}</Typography>
                                            <Button href={bankData?.attachment} target="_blank" size="small" variant="contained" color="secondary" component={Link}>
                                                {t('View')}
                                            </Button>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Service')}</Typography>
                                            <Typography sx={{ mb: 1 }}>{i18n.language==='ar'?bankData?.service?.title_ar:bankData?.service?.title_en}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Company')}</Typography>
                                            <Typography sx={{ mb: 1 }}>{bankData?.company?.name}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Phone')}</Typography>
                                            {
                                                bankData?.company?.phone ? (
                                                    <Link href={`tel:${bankData?.company?.phone}`} sx={{ mb: 1 }}>{
                                                        bankData?.company?.phone[0] === '5'?('0'+bankData?.company?.phone):bankData?.company?.phone
                                                    }</Link>
                                                ):(
                                                    'N/A'
                                                )
                                            }
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Email')}</Typography>
                                            {
                                                bankData?.company?.email ? (
                                                    <Link href={`mailto:${bankData?.company?.email}`} sx={{ mb: 1 }}>{bankData?.company?.email}</Link>
                                                ):(
                                                    'N/A'
                                                )
                                            }
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{my:1}}>
                                            <Typography sx={{ mb: 1, fontWeight: 'bold' }}>{t('Status')}</Typography>
                                            {
                                                bankData?.is_verified ? <Chip label={t('Verified')} color="success" />:<Chip label={t('Not Verified')} color="error" />
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleBankInfoClose} variant="outlined" color="error">
                                    {t('Close')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </React.Fragment>
                )
            }
        </>
    );
};

export default CompanyBank;
