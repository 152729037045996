import React, { useMemo, useState, useEffect, useContext } from 'react';
import {
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Typography,
    Box,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import ToastContext from 'context/ToastContext';
import {getAllBlogs, newsletterSubscribers, readNotification} from 'api/api';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {getAllNotification} from "../../store/actions";
import {useDispatch} from 'react-redux';
const NewsletterSubscribers = () => {
    const toastOptions = useContext(ToastContext);
    const [rows, setrows] = useState([]);
    const [IsActive, setIsActive] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [editData, setEditData] = useState();
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();



    useEffect(() => {
        newsletterSubscribers().then((res) => {
            setrows(res.data.data);
            setIsActive(false);
        });
        var user_id = JSON.parse(localStorage.getItem('auth_user'))?.user?._id
        readNotification({user_id: user_id, type: 'NEWSLETTER'}).then((res) => {
            dispatch(getAllNotification({user_id: user_id}));
        });
    }, []);
    const columns = useMemo(() => [
        {
            field: 'email',
            headerName: t('Email'),
            flex: 1,

            renderCell: ({ row }) => {
                return (i18n.language === 'ar' ? row.title_ar : row.title_en);
            }
        },
        {
            field: 'date',
            headerName: t('Date'),
            flex: 1,

            renderCell: ({ row }) => {
                return moment(row.createdAt).format('DD MMM YY');
            }
        },

    ]);

    return (
        <>
            {IsActive ? (
                <SkeletonEarningCard />
            ) : (
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <Typography variant="h3">{t('Newsletter Subscribers')} ({rows?.length ?? 0})</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {' '}
                                        <DataGrid
                                            disableRowSelectionOnClick
                                            getRowHeight={() => 'auto'}
                                                      getEstimatedRowHeight={() => 200}
                                            getRowId={(row) => row._id}
                                            rows={rows}
                                            columns={columns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: { page: 0, pageSize: 10 }
                                                }
                                            }}
                                            pageSizeOptions={[5, 10]}
                                            sx={{
                                                border: 0,
                                                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                                                    outline: 'none !important'
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default NewsletterSubscribers;
