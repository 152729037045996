import React, { useMemo, useState, useEffect, useContext } from 'react';
import {
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Typography,
    Box,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack
} from '@mui/material';
import AWS from 'aws-sdk';

import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import ToastContext from 'context/ToastContext';
import {
    getallNotification,
    createPushNotification,
    getAllServices,
    getallCompanies,
    sendPushNotification, editPushNotification
} from 'api/api';
import { useNavigate } from 'react-router-dom';
import DropZone from 'ui-component/DropZone';
import {useTranslation} from "react-i18next";

const PushNotification = () => {
    AWS.config.update({
        accessKeyId: 'AKIAUGIO6MJREH5SOX4S',
        secretAccessKey: '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',
        region: 'ap-southeast-1'
    });
    const toastOptions = useContext(ToastContext);
    const [rows, setrows] = useState([]);
    const [IsActive, setIsActive] = useState(true);
    const [saveImage, setsaveImage] = useState(false);
    const [CRcertificate, setCRcertificate] = useState('');
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [editData, setEditData] = useState();
    const [allServuces, setAllServices] = useState();
    const [AllCompanies, setAllCompanies] = useState();
    const [uploadLink, setuploadLink] = useState('');
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();


    const handleClickOpen = (data, type) => {
        setOpen(true);
        data.type = type;
        setEditData(data);
    };
    const handleClickOpen2 = (data) => {
        setOpen2(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClose2 = () => {
        setOpen2(false);
    };
    useEffect(() => {
        getallNotification().then((res) => {
            setrows(res.data.data);
            setIsActive(false);
        });
    }, []);
    const columns = useMemo(() => [
        {
            field: 'title_en',
            headerName: t('Title'),
            flex: 1,
            renderCell: ({ row }) => {
                return (i18n.language==='ar'?row.title_ar:row.title_en);
            }
        },
        {
            field: 'desc_en',
            headerName: t('Description'),
            flex: 1,
            renderCell: ({ row }) => {
                return (i18n.language==='ar'?row.desc_ar:row.desc_en);
            }
        },
        {
            field: 'platform',
            headerName: t('Platform'),
            flex: 1,
            renderCell: ({ row }) => {
                if(row.platform === 'ANDROID') {
                    return 'Android';
                }
                else if(row.platform === 'ALL') {
                    return 'All';
                }
                else{
                    return (row.platform);
                }

            }
        },
        {
            field: 'app_type',
            headerName: t('App Type'),
            flex: 1,
            renderCell: ({ row }) => {
                if(row.app_type === 'PROVIDER'){
                    return ('Provider');
                }
                else{
                    return ('Customer');
                }
            }
        },
        {
            field: 'action',
            headerName: t('Action'),
            flex: 1,
            type: 'actions',
            getActions: ({ row }) => {
                return [
                        <Button variant="contained" color="success" onClick={() => handleClickOpen(row, 'SEND')}>{t('Send')}</Button>,
                        <Button variant="contained" color="secondary" onClick={() => handleClickOpen(row, 'EDIT')}>{t('Edit')}</Button>
                    ]
            }
        },


    ]);


    return (
        <>
            {IsActive ? (
                <SkeletonEarningCard />
            ) : (
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <Typography variant="h3">{t('Push Notification')}</Typography>
                                            <Button variant="contained" onClick={() => handleClickOpen2()}>
                                                {t('Send Notification')}
                                            </Button>
                                        </Box>
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {' '}
                                        <DataGrid
                                            getRowHeight={() => 'auto'}
                                                      getEstimatedRowHeight={() => 200}
                                            rows={rows}
                                            getRowId={(row) => row._id}
                                            disableRowSelectionOnClick
                                            columns={columns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: { page: 0, pageSize: 10 }
                                                }
                                            }}
                                            pageSizeOptions={[5, 10]}
                                            // checkboxSelection
                                            sx={{
                                                border: 0,
                                                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                                                    outline: 'none !important'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}

            <Dialog open={open2} onClose={handleClose2} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <Formik
                    initialValues={{
                        desc_en: '',
                        desc_ar: '',
                        title_ar: '',
                        title_en: '',
                        platform: '',
                        app_type: '',
                        user_id: JSON.parse(localStorage.getItem('auth_user'))?.user?._id
                    }}
                    onSubmit={async (values) => {
                        createPushNotification(values)
                            .then((res) => {
                                if(res.data.code === 1){
                                    toast.success(i18n.language==='ar'?res.data.ar_message:res.data.en_message, toastOptions);
                                    setrows(res.data.data);
                                    handleClose2();
                                }
                                else{
                                    toast.error(i18n.language==='ar'?res.data.ar_message:res.data.en_message, toastOptions);
                                }
                            })
                            .catch((err) => {
                                if (err?.response?.status === 401) {
                                    toast.error(err.response?.data?.error, toastOptions);
                                }
                            });
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {

                        return (
                            <form noValidate onSubmit={handleSubmit}>
                                <>
                                    <DialogContent>
                                        <Grid container spacing={2}>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography variant="h3" sx={{ mb: 2 }}>
                                                    {t('Send Notification')}
                                                </Typography>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography sx={{ mb: 1 }}>{t('App Type')}</Typography>
                                                <FormControl fullWidth>
                                                    <Select name="app_type" value={values.app_type} onChange={handleChange}>
                                                        <MenuItem value="PROVIDER">{t('Provider')}</MenuItem>
                                                        <MenuItem value="CUSTOMER">{t('Customer')}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography sx={{ mb: 1 }}>{t('Platform')}</Typography>
                                                <FormControl fullWidth>
                                                    <Select name="platform" value={values.platform} onChange={handleChange}>
                                                        <MenuItem value="ALL">{t('All')}</MenuItem>
                                                        <MenuItem value="IOS">{t('IOS')}</MenuItem>
                                                        <MenuItem value="ANDROID">{t('Android')}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography sx={{ mb: 1 }}>{t('Title English')}</Typography>
                                                <FormControl fullWidth>
                                                    <TextField name="title_en" value={values.title_en} onChange={handleChange} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography sx={{ mb: 1 }}>{t('Title Arabic')}</Typography>
                                                <FormControl fullWidth>
                                                    <TextField name="title_ar" value={values.title_ar} onChange={handleChange} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography sx={{ mb: 1 }}>{t('Description English')}</Typography>
                                                <FormControl fullWidth>
                                                    <TextField name="desc_en" value={values.desc_en} onChange={handleChange} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12}>
                                                <Typography sx={{ mb: 1 }}>{t('Description Arabic')}</Typography>
                                                <FormControl fullWidth>
                                                    <TextField name="desc_ar" value={values.desc_ar} onChange={handleChange} />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button type="submit" variant="contained">
                                            Add
                                        </Button>
                                    </DialogActions>
                                </>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>
            {
                editData && (
                    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <Formik
                            initialValues={{
                                desc_en: editData.desc_en,
                                desc_ar: editData.desc_ar,
                                title_ar: editData.title_ar,
                                title_en: editData.title_en,
                                platform: editData.platform,
                                app_type: editData.app_type,
                                notify_id: editData._id,
                                user_id: JSON.parse(localStorage.getItem('auth_user'))?.user?._id
                            }}
                            onSubmit={async (values) => {
                                if(editData.type === 'EDIT'){
                                    editPushNotification(values)
                                        .then((res) => {
                                            if(res.data.code === 1){
                                                toast.success(i18n.language==='ar'?res.data.ar_message:res.data.en_message, toastOptions);
                                                setrows(res.data.data);
                                                handleClose();
                                            }
                                            else{
                                                toast.error(i18n.language==='ar'?res.data.ar_message:res.data.en_message, toastOptions);
                                            }
                                        })
                                        .catch((err) => {
                                            if (err?.response?.status === 401) {
                                                toast.error(err.response?.data?.error, toastOptions);
                                            }
                                        });
                                }
                                else {
                                    sendPushNotification(values)
                                        .then((res) => {
                                            if(res.data.code === 1){
                                                toast.success(i18n.language==='ar'?res.data.ar_message:res.data.en_message, toastOptions);
                                                setrows(res.data.data);
                                                handleClose();
                                            }
                                            else{
                                                toast.error(i18n.language==='ar'?res.data.ar_message:res.data.en_message, toastOptions);
                                            }
                                        })
                                        .catch((err) => {
                                            if (err?.response?.status === 401) {
                                                toast.error(err.response?.data?.error, toastOptions);
                                            }
                                        });
                                }
                            }}
                        >
                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {

                                return (
                                    <form noValidate onSubmit={handleSubmit}>
                                        <>
                                            <DialogContent>
                                                <Grid container spacing={2}>


                                                    {
                                                        editData.type === 'EDIT'?(
                                                            <>
                                                                <Grid item lg={12} md={12} sm={12}>
                                                                    <Typography variant="h3" sx={{ mb: 2 }}>
                                                                        {t('Edit Notification')}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item lg={12} md={12} sm={12}>
                                                                    <Typography sx={{ mb: 1 }}>{t('App Type')}</Typography>
                                                                    <FormControl fullWidth>
                                                                        <Select name="app_type" value={values.app_type} onChange={handleChange}>
                                                                            <MenuItem value="PROVIDER">{t('Provider')}</MenuItem>
                                                                            <MenuItem value="CUSTOMER">{t('Customer')}</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item lg={12} md={12} sm={12}>
                                                                    <Typography sx={{ mb: 1 }}>{t('Platform')}</Typography>
                                                                    <FormControl fullWidth>
                                                                        <Select name="platform" value={values.platform} onChange={handleChange}>
                                                                            <MenuItem value="ALL">{t('All')}</MenuItem>
                                                                            <MenuItem value="IOS">{t('IOS')}</MenuItem>
                                                                            <MenuItem value="ANDROID">{t('Android')}</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>

                                                                <Grid item lg={12} md={12} sm={12}>
                                                                    <Typography sx={{ mb: 1 }}>{t('Title English')}</Typography>
                                                                    <FormControl fullWidth>
                                                                        <TextField name="title_en" value={values.title_en} onChange={handleChange} />
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item lg={12} md={12} sm={12}>
                                                                    <Typography sx={{ mb: 1 }}>{t('Title Arabic')}</Typography>
                                                                    <FormControl fullWidth>
                                                                        <TextField name="title_ar" value={values.title_ar} onChange={handleChange} />
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item lg={12} md={12} sm={12}>
                                                                    <Typography sx={{ mb: 1 }}>{t('Description English')}</Typography>
                                                                    <FormControl fullWidth>
                                                                        <TextField name="desc_en" value={values.desc_en} onChange={handleChange} />
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item lg={12} md={12} sm={12}>
                                                                    <Typography sx={{ mb: 1 }}>{t('Description Arabic')}</Typography>
                                                                    <FormControl fullWidth>
                                                                        <TextField name="desc_ar" value={values.desc_ar} onChange={handleChange} />
                                                                    </FormControl>
                                                                </Grid>
                                                            </>
                                                        ):(

                                                            <>
                                                                <Grid item lg={12} md={12} sm={12}>
                                                                    <Typography variant="h3" sx={{ mb: 2 }}>
                                                                        {t('Alert')}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item lg={12} md={12} sm={12}>
                                                                    <Typography sx={{ mb: 1 }}>{t('Are you sure you want to send notification?')}</Typography>
                                                                </Grid>
                                                            </>
                                                        )
                                                    }

                                                </Grid>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button type="submit" variant="contained">
                                                    {
                                                        editData.type === 'EDIT' ?(
                                                            t('Update')
                                                        ):(
                                                            t('Send')
                                                        )
                                                    }
                                                </Button>
                                            </DialogActions>
                                        </>
                                    </form>
                                );
                            }}
                        </Formik>
                    </Dialog>
                )
            }

        </>
    );
};

export default PushNotification;
