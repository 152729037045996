import {useDispatch, useSelector} from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import NavigationScroll from 'layout/NavigationScroll';
// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';
import 'react-toastify/dist/ReactToastify.min.css';
import {toast, ToastContainer} from 'react-toastify';
import ToastContext from './context/ToastContext';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {SET_FONT_FAMILY} from "./store/actions";
import {CacheProvider} from "@emotion/react";
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from  'stylis';
import {verifyToken} from "./api/api";
import moment from "moment/moment";
import {getTokens, onMessageListener} from './config/firebase'

import { useGeolocated  } from "react-geolocated";

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const cacheLtr = createCache({
    key: 'muiltr',
    stylisPlugins: [prefixer],
});

const toastOptions = {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
};
// project imports

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    let navigate = useNavigate();
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [isTokenFound, setTokenFound] = useState(false);
    const [show, setShow] = useState(false);

    let user = JSON.parse(localStorage.getItem('auth_user'))?.user;
    if(user && location.pathname !== '/google2f' && location.pathname !== '/otp' && location.pathname !== '/login' && user.user_type !== 'ADMIN' && user.email !== 'hr@ocit.sa'){
        let time_stamp = JSON.parse(localStorage.getItem('auth_user'))?.time_stamp;
        time_stamp = parseInt(time_stamp);

        const curr_time_stamp = moment().unix();
        let isVerify = user.is_2fa_enabled;
        if(!time_stamp || (time_stamp < curr_time_stamp)){

            isVerify = false;
        }
        verifyToken({user_id: user?._id, is_verify: isVerify}).then((res) => {
            if(!isVerify){
                let i18nextLng = localStorage.getItem('i18nextLng');
                localStorage.clear();
                localStorage.setItem('i18nextLng', i18nextLng);
                navigate('/login');
            }
            else if(res.data.code === 1){
                localStorage.setItem(
                    'auth_user',
                    JSON.stringify({
                        user: res.data.data,
                        time_stamp: JSON.parse(localStorage.getItem('auth_user'))?.time_stamp
                    })
                );
            }
            else if(res.data.code === 2){
                navigate('/google2f');
            }
            else{
                navigate('/otp');
            }
        });


    }

    if(!window.location.href.includes('app-')){
        getTokens(setTokenFound);
        const { coords, isGeolocationAvailable, isGeolocationEnabled } =
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useGeolocated({
                positionOptions: {
                    enableHighAccuracy: false,
                },
                userDecisionTimeout: 5000,
            });

        if(isGeolocationAvailable) {

            if(isGeolocationEnabled){

                if(coords){
                    localStorage.setItem('lat', coords.latitude);
                    localStorage.setItem('lng', coords.longitude);
                }
            }
        }

        onMessageListener().then(payload=>{
            toast(payload.notification.body);
            setShow(true)
        }).catch(err=>console.log(err))
    }


    const dispatch = useDispatch();
    useEffect(() => {
        i18n.language==='ar'?dispatch({ type: SET_FONT_FAMILY, font: 'Alexandria'}):dispatch({ type: SET_FONT_FAMILY, font: 'Karla'});


    }, [dispatch]);

    return (
        <ToastContext.Provider value={toastOptions}>
            <StyledEngineProvider injectFirst>
                <CacheProvider value={i18n.language==='ar'?cacheRtl:cacheLtr}>
                    <ThemeProvider theme={themes(customization, i18n.language === 'ar')}>
                        <CssBaseline />
                        <NavigationScroll>
                            <ToastContainer />
                            <Routes />
                        </NavigationScroll>
                    </ThemeProvider>
                </CacheProvider>
            </StyledEngineProvider>
        </ToastContext.Provider>
    );
};

export default App;
