import React, { useState, useContext, useEffect } from 'react';
import {
    Grid,
    Typography,
    Button,
    Divider,
    TextField,
    InputAdornment,
    FormHelperText,
    MenuItem,
    Select as MSelect,
    Box,
    Autocomplete
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useNavigate, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DropZone from 'ui-component/DropZone';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import ToastContext from 'context/ToastContext';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AWS from 'aws-sdk';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';
import {useTranslation} from "react-i18next";
import imageCompression from "browser-image-compression";
import {getalladminAppSetting, editBlog, createBlog, updateBlog, getallBlogCategory} from "../../api/api";

const EditBlog = () => {
    let current = JSON.parse(localStorage.getItem('auth_user'));
    let service_id = localStorage.getItem('serv-id');

    const toastOptions = useContext(ToastContext);
    let navigate = useNavigate();
    let service = useParams();
    const [ProductImage, setProductImage] = useState();
    const [serviceCategory, setServiceCategory] = useState([]);
    const [UnitsData, setUnitsData] = useState([]);
    const [ButtonLoading, setButtonLoading] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const [settings, setSettings] = useState();
    const [blogData, setBlogData] = useState();
    const [blogCategory, setBlogCategory] = useState([]);

    useEffect(() => {
        getallBlogCategory({status: true}).then((res) => {
            setBlogCategory(res.data.data);
        });
        editBlog({blog_id: localStorage.getItem('blog-id')}).then((res) => {
            setBlogData(res.data.data);
            setLoading(false);
        });
        getalladminAppSetting().then((res) => {
            setSettings(res.data.data);
        });
    }, []);

    const handleFileUpload = () => {
        
    }

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12}>
                {isLoading ? (
                    <SkeletonEarningCard />
                ) : (
                    <Card sx={{ minWidth: 250, cursor: 'pointer' }} elevation={2}>
                        <CardContent>
                            <Grid container spacing={gridSpacing}>
                                <Grid item lg={12}>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Box>
                                            <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{t('Blogs')}</Typography>
                                            <Typography sx={{ color: 'gray' }}>{t('Edit Blog')}</Typography>
                                        </Box>
                                        <Box>
                                            <Button
                                                onClick={() => navigate(-1)}
                                                variant="contained"
                                                color="secondary"
                                                sx={{
                                                    mt: 0.7,
                                                }}
                                            >
                                                {t('Back')}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
                {isLoading ? (
                    <SkeletonEarningCard />
                ) : (
                    <Formik
                        initialValues={{
                            blog_id: blogData._id,
                            status: blogData.status,
                            title_en: blogData.title_en,
                            title_ar: blogData.title_ar,
                            desc_en: blogData.desc_en,
                            desc_ar: blogData.desc_ar,
                            blog_cat_id: blogData.blog_cat_id,
                            cover_image: blogData.cover_image,
                            feature_image: blogData.feature_image,
                            keyword_arr: blogData.keyword_arr,
                        }}
                        validationSchema={Yup.object().shape({
                            title_en: Yup.string().max(255).required(t('Title English is required')),
                            title_ar: Yup.string().max(255).required(t('Title Arabic is required')),
                            desc_en: Yup.string().required(t('Description English is required')),
                            desc_ar: Yup.string().required(t('Description Arabic is required')),
                            blog_cat_id: Yup.string().max(255).required(t('Category is required')),
                            keyword_arr: Yup.array()
                                .of(Yup.string()).min(1, t('Keywords are required')).required(t('Keywords are required')),
                        })}
                        onSubmit={async (values) => {
                            if (!values.cover_image) {
                                toast.error(t('Cover image is required'));
                            } else if (!values.feature_image) {
                                toast.error(t('Feature image is required'));
                            } else {
                                setButtonLoading(true);
                                try {
                                    if(values.cover_image !== blogData.cover_image) {
                                        AWS.config.update({
                                            accessKeyId: settings.aws_access_key,
                                            secretAccessKey: settings.aws_secret_key,
                                            region: settings.aws_region
                                        });

                                        const s3 = new AWS.S3();
                                        let extension = values.cover_image.name.split('.').pop();
                                        let params = {
                                            ACL: 'public-read',
                                            Bucket: settings.aws_bucket_name,
                                            Key: `omran/blog_image/${Date.parse(new Date())}_cover.${extension}`,
                                            Body: values.cover_image
                                        };

                                        let response = await s3.upload(params).promise();
                                        values.cover_image = response.Location
                                    }
                                    if(values.feature_image !== blogData.feature_image){
                                        AWS.config.update({
                                            accessKeyId: settings.aws_access_key,
                                            secretAccessKey: settings.aws_secret_key,
                                            region: settings.aws_region
                                        });

                                        const s3 = new AWS.S3();
                                        const extension = values.feature_image.name.split('.').pop();
                                        const params = {
                                            ACL: 'public-read',
                                            Bucket: settings.aws_bucket_name,
                                            Key: `omran/blog_image/${Date.parse(new Date())}_feature.${extension}`,
                                            Body: values.feature_image
                                        };

                                        const response = await s3.upload(params).promise();
                                        values.feature_image = response.Location;
                                    }

                                    updateBlog(values).then((res) => {
                                        setButtonLoading(false)
                                        const response = res.data;
                                        if (response.code === 1) {
                                            navigate(`/blogs`);
                                            toast.success(i18n.language === 'ar' ? response.ar_message : response.en_message)
                                        } else {
                                            toast.error(i18n.language === 'ar' ? response.ar_message : response.en_message)
                                        }
                                    })
                                        .catch((err) => {
                                            const response = err.response?.data;
                                            toast.error(i18n.language === 'ar' ? response.ar_message : response.en_message)
                                        })
                                } catch (error) {
                                    console.error('Error uploading files:', error);
                                }
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container
                                      justifyContent="center"
                                      alignItems="center" spacing={gridSpacing}>
                                    <Grid item lg={8} md={8} sm={12}>
                                        <Card sx={{minWidth: 250}}>
                                            <CardContent>
                                                <Grid container spacing={gridSpacing}>
                                                    <Grid item lg={6} md={6} sm={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12}>
                                                                <Typography variant="h4">{t('Status')}</Typography>
                                                                <Divider sx={{mt: 2}}/>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <Typography
                                                                    sx={{mb: 1}}>{t('Select Status')}</Typography>
                                                                <MSelect
                                                                    fullWidth
                                                                    name="status"
                                                                    onChange={handleChange}
                                                                    value={values.status}
                                                                    onBlur={handleBlur}
                                                                    error={Boolean(touched.status && errors.status)}
                                                                >
                                                                    <MenuItem key={'s-1'}
                                                                              value={true}>{t('Active')}</MenuItem>
                                                                    <MenuItem key={'s-2'}
                                                                              value={false}>{t('Inactive')}</MenuItem>
                                                                </MSelect>
                                                                {touched.blog_cat_id && errors.blog_cat_id && (
                                                                    <FormHelperText error
                                                                                    id="standard-weight-helper-text-blog_cat_id-login">
                                                                        {errors.blog_cat_id}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={6} md={6} sm={6}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12}>
                                                                <Typography variant="h4">{t('Category')}</Typography>
                                                                <Divider sx={{mt: 2}}/>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <Typography
                                                                    sx={{mb: 1}}>{t('Select Category')}</Typography>
                                                                <MSelect
                                                                    fullWidth
                                                                    name="blog_cat_id"
                                                                    onChange={handleChange}
                                                                    value={values.blog_cat_id}
                                                                    onBlur={handleBlur}
                                                                    error={Boolean(touched.blog_cat_id && errors.blog_cat_id)}
                                                                >
                                                                    {
                                                                        blogCategory?.map((e) => (
                                                                            <MenuItem key={e._id}
                                                                                      value={e._id}>{i18n.language === 'ar' ? e.title_ar : e.title_en}</MenuItem>
                                                                        ))
                                                                    }
                                                                </MSelect>
                                                                {touched.blog_cat_id && errors.blog_cat_id && (
                                                                    <FormHelperText error
                                                                                    id="standard-weight-helper-text-blog_cat_id-login">
                                                                        {errors.blog_cat_id}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12}>
                                                                <Typography variant="h4">{t('Title')}</Typography>
                                                                <Divider sx={{mt: 2}}/>
                                                            </Grid>
                                                            <Grid item lg={6} md={6}>
                                                                <Typography sx={{mb: 1}}>{t('Title English')}<span
                                                                    style={{color: 'red'}}>*</span></Typography>
                                                                <TextField
                                                                    placeholder={t('Title English')}
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="title_en"
                                                                    value={values.title_en}
                                                                    error={Boolean(touched.title_en && errors.title_en)}
                                                                />
                                                                {touched.title_en && errors.title_en && (
                                                                    <FormHelperText error
                                                                                    id="standard-weight-helper-text-title_en-login">
                                                                        {errors.title_en}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={6} md={6}>
                                                                <Typography sx={{mb: 1}}>{t('Title Arabic')}<span
                                                                    style={{color: 'red'}}>*</span></Typography>
                                                                <TextField
                                                                    placeholder={t('Title Arabic')}
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="title_ar"
                                                                    value={values.title_ar}
                                                                    error={Boolean(touched.title_ar && errors.title_ar)}
                                                                />
                                                                {touched.title_ar && errors.title_ar && (
                                                                    <FormHelperText error
                                                                                    id="standard-weight-helper-text-title_ar-login">
                                                                        {errors.title_ar}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12}>
                                                                <Typography variant="h4">{t('Description')}</Typography>
                                                                <Divider sx={{mt: 2}}/>
                                                            </Grid>
                                                            <Grid item lg={6} md={6}>
                                                                <Typography sx={{mb: 1}}>{t('Description English')}<span
                                                                    style={{color: 'red'}}>*</span></Typography>
                                                                {
                                                                    i18n.language === 'ar' ? (
                                                                        <CKEditor
                                                                            id="ck-ar"
                                                                            config={{language: 'ar'}}
                                                                            editor={ClassicEditor}
                                                                            data={values.desc_en}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('desc_en', data);
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CKEditor
                                                                            id="ck-en"
                                                                            config={{language: 'en'}}
                                                                            editor={ClassicEditor}
                                                                            data={values.desc_en}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('desc_en', data);
                                                                            }}
                                                                        />
                                                                    )
                                                                }
                                                                {touched.desc_en && errors.desc_en && (
                                                                    <FormHelperText error
                                                                                    id="standard-weight-helper-text-desc_en-login">
                                                                        {errors.desc_en}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={6} md={6}>
                                                                <Typography sx={{mb: 1}}>{t('Description Arabic')}<span
                                                                    style={{color: 'red'}}>*</span></Typography>
                                                                {
                                                                    i18n.language === 'ar' ? (
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            config={{language: 'ar'}}
                                                                            data={values.desc_ar}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('desc_ar', data);
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            config={{language: 'en'}}
                                                                            data={values.desc_ar}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                setFieldValue('desc_ar', data);
                                                                            }}
                                                                        />
                                                                    )
                                                                }
                                                                {touched.desc_ar && errors.desc_ar && (
                                                                    <FormHelperText error
                                                                                    id="standard-weight-helper-text-desc_ar-login">
                                                                        {errors.desc_ar}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12}>
                                                                <Typography variant="h4">{t('Images')}</Typography>
                                                                <Divider sx={{mt: 2, mb: 2}}/>
                                                            </Grid>
                                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                <Typography sx={{mb: 1}}>{t('Cover Image')}<span
                                                                    style={{color: 'red'}}>*</span></Typography>
                                                                <DropZone key={0} isFile={false} onChange={(event) => {
                                                                    setFieldValue('cover_image', event[0]);
                                                                }}/>
                                                                <Typography sx={{fontSize: '12px', color: '#959595'}}>{t('min: 1440px x 430px')}</Typography>
                                                                <img src={blogData.cover_image} alt="cover image" style={{height: '50px'}}/>
                                                            </Grid>
                                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                                <Typography sx={{mb: 1}}>{t('Feature Image')}<span
                                                                    style={{color: 'red'}}>*</span></Typography>
                                                                <DropZone key={1} isFile={false} onChange={(event) => {
                                                                    setFieldValue('feature_image', event[0]);
                                                                }}/>
                                                                <Typography sx={{fontSize: '12px', color: '#959595'}}>{t('min: 384px x 241px')}</Typography>
                                                                <img src={blogData.feature_image} alt="feature image" style={{height: '50px'}} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12}>
                                                                <Typography variant="h4">{t('Meta Info')}</Typography>
                                                                <Divider sx={{mt: 2, mb: 2}}/>
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                <Typography sx={{mb: 1}}>{t('Keywords')}<span
                                                                    style={{color: 'red'}}>*</span></Typography>
                                                                <Autocomplete sx={{mt: 0}}
                                                                              multiple
                                                                              freeSolo
                                                                              options={values.keyword_arr}
                                                                              defaultValue={values.keyword_arr}
                                                                              onChange={(event, value) => {
                                                                                  setFieldValue('keyword_arr', value);
                                                                              }}
                                                                              renderInput={params => (
                                                                                  <TextField sx={{mt: 0}}
                                                                                             {...params}
                                                                                             placeholder={t('Keywords')}
                                                                                             margin="normal"
                                                                                             fullWidth
                                                                                  />

                                                                              )}
                                                                />
                                                                {touched.keyword_arr && errors.keyword_arr && (
                                                                    <FormHelperText error
                                                                                    id="standard-weight-helper-text-keyword_arr-login">
                                                                        {errors.keyword_arr}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={12} md={12} sm={12}>
                                                    <LoadingButton
                                                        type="submit"
                                                        variant="contained"
                                                        color="secondary"
                                                        loading={ButtonLoading}
                                                        sx={{mt: 1}}
                                                        loadingPosition="center"
                                                    >
                                                        {t('Save Service')}
                                                    </LoadingButton>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                )}
            </Grid>
        </Grid>
    );
};

const top100Films = [
    {label: 'The Shawshank Redemption', year: 1994},
    {label: 'The Godfather', year: 1972}
];

export default EditBlog;
