// assets
import React, { useEffect } from 'react';
import { IconDashboard } from '@tabler/icons';
import CategoryIcon from '@mui/icons-material/Category';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ArticleIcon from '@mui/icons-material/Article';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import HomeIcon from '@mui/icons-material/Home';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const parse = JSON.parse(localStorage.getItem('auth_user_type'));
const current_role = parse?.type;

const dashboard = {
    id: 'dashboard',
    title: '',
    type: 'group',
    children:
        current_role === 'ADMIN' || current_role === 'SUB-ADMIN' || current_role === 'DEVELOPER'
            ? [
                  {
                      id: 'dashboard',
                      title: 'Dashboard',
                      type: 'item',
                      url: '/dashboard',
                      icon: icons.IconDashboard,
                      breadcrumbs: false
                  },
                  {
                      id: 'Services',
                      title: 'Services & Categories',
                      type: 'item',
                      url: '/Services_Categories',
                      icon: CategoryIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'Companies',
                      title: 'Companies',
                      type: 'item',
                      url: '/Companies',
                      icon: ApartmentIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'Company Services',
                      title: 'Company Services',
                      type: 'item',
                      url: '/CompanyServies',
                      icon: DesignServicesIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'Company Projects',
                      title: 'Company Projects',
                      type: 'item',
                      url: '/CompanyProject',
                      icon: AccountTreeIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'Products',
                      title: 'Products',
                      type: 'item',
                      url: '/Products',
                      icon: EmojiTransportationIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'Orders',
                      title: 'Orders',
                      type: 'item',
                      url: '/Orders',
                      icon: AddShoppingCartIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'Inquiries',
                      title: 'Inquiries',
                      type: 'item',
                      url: '/Inquiries',
                      icon: SummarizeIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'Quotations',
                      title: 'Quotations',
                      type: 'item',
                      url: '/Quotations',
                      icon: ArticleIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'Units',
                      title: 'Units',
                      type: 'item',
                      url: '/Units',
                      icon: AdUnitsIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'CategoryRequest',
                      title: 'Category Requests',
                      type: 'item',
                      url: '/category-request',
                      icon: AdUnitsIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'Customer Home',
                      title: 'Customer Home',
                      type: 'collapse',
                      url: '/CustomerHome',
                      icon: HomeIcon,
                      breadcrumbs: false,
                      children: [
                          {
                              id: 'Banners',
                              title: 'Banners',
                              type: 'item',
                              url: '/Banners',
                              breadcrumbs: false
                          },
                          {
                              id: 'FeatureProduct',
                              title: 'Feature Products',
                              type: 'item',
                              url: '/FeatureProduct',
                              breadcrumbs: false
                          },
                          {
                              id: 'FeatureCompany',
                              title: 'Feature Companies',
                              type: 'item',
                              url: '/FeatureCompanies',
                              breadcrumbs: false
                          },
                          {
                              id: 'HomepageVideo',
                              title: 'Home Page Video',
                              type: 'item',
                              url: '/HomePageVideo',
                              breadcrumbs: false
                          }
                      ]
                  },
                  {
                      id: 'Providers',
                      title: 'Providers',
                      type: 'item',
                      url: '/Providers',
                      icon: RecentActorsIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'Customers',
                      title: 'Customers',
                      type: 'item',
                      url: '/Customers',
                      icon: PersonPinIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'Settings',
                      title: 'Settings',
                      type: 'item',
                      url: '/Setting',
                      icon: SettingsOutlinedIcon,
                      breadcrumbs: false
                  }
              ]
            : current_role === 'CUSTOMER-SUPPORT'
            ? [
                  {
                      id: 'dashboard',
                      title: 'Dashboard',
                      type: 'item',
                      url: '/dashboard',
                      icon: icons.IconDashboard,
                      breadcrumbs: false
                  },
                  {
                      id: 'Companies',
                      title: 'Companies',
                      type: 'item',
                      url: '/Companies',
                      icon: ApartmentIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'Providers',
                      title: 'Providers',
                      type: 'item',
                      url: '/Providers',
                      icon: RecentActorsIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'Customers',
                      title: 'Customers',
                      type: 'item',
                      url: '/Customers',
                      icon: PersonPinIcon,
                      breadcrumbs: false
                  }
              ]
            : current_role === 'MARKETING'
            ? [
                  {
                      id: 'dashboard',
                      title: 'Dashboard',
                      type: 'item',
                      url: '/dashboard',
                      icon: icons.IconDashboard,
                      breadcrumbs: false
                  },
                  {
                      id: 'Companies',
                      title: 'Companies',
                      type: 'item',
                      url: '/Companies',
                      icon: ApartmentIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'Orders',
                      title: 'Orders',
                      type: 'item',
                      url: '/Orders',
                      icon: AddShoppingCartIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'Providers',
                      title: 'Providers',
                      type: 'item',
                      url: '/Providers',
                      icon: RecentActorsIcon,
                      breadcrumbs: false
                  },
                  {
                      id: 'Customers',
                      title: 'Customers',
                      type: 'item',
                      url: '/Customers',
                      icon: PersonPinIcon,
                      breadcrumbs: false
                  }
              ]
            : current_role === 'SALE'?
                [
                            {
                                id: 'dashboard',
                                title: 'Dashboard',
                                type: 'item',
                                url: '/dashboard',
                                icon: icons.IconDashboard,
                                breadcrumbs: false
                            },
                            {
                                id: 'Companies',
                                title: 'Companies',
                                type: 'item',
                                url: '/Companies',
                                icon: ApartmentIcon,
                                breadcrumbs: false
                            },

                            {
                                id: 'Providers',
                                title: 'Providers',
                                type: 'item',
                                url: '/Providers',
                                icon: RecentActorsIcon,
                                breadcrumbs: false
                            }
        ]
            : [
                  {
                      id: 'dashboard',
                      title: 'Dashboard',
                      type: 'item',
                      url: '/dashboard',
                      icon: icons.IconDashboard,
                      breadcrumbs: false
                  }
              ]
};

export default dashboard;
