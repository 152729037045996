// reducers.js
const initialState = {
    loading: true,
    data: null,
    // Add other initial state properties as needed
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NOTIFICATION_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_NOTIFICATION':
            return { ...state, data: action.payload };
        // Add cases for other actions as needed
        default:
            return state;
    }
};

export default notificationReducer;
