import React, { useMemo, useState, useEffect, useContext } from 'react';
import {
    Button,
    Card,
    CardContent,
    Grid,
    TextField,
    Typography,
    Box,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';

import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import ToastContext from 'context/ToastContext';
import { getallBlogCategory, updateBlogCategory, createBlogCategory } from 'api/api';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";
const BlogCategory = () => {
    const toastOptions = useContext(ToastContext);
    const [rows, setrows] = useState([]);
    const [IsActive, setIsActive] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [editData, setEditData] = useState();
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const handleClickOpen = (data) => {
        setOpen(true);
        setEditData(data);
    };

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        getallBlogCategory().then((res) => {
            setrows(res.data.data);
            setIsActive(false);
        });
    }, []);
    const columns = useMemo(() => [
        {
            field: 'title_en',
            headerName: t('Title English'),
            flex: 1
        },
        {
            field: 'title_ar',
            headerName: t('Title Arabic'),
            flex: 1,
        },
        {
            field: 'status',
            headerName: t('Status'),
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Chip
                        label={row.status?t('Active'):t('Inactive')}
                        sx={
                            row.status
                                ? { backgroundColor: 'green', color: 'white' }
                                : { backgroundColor: 'red', color: 'white' }
                        }
                    />
                );
            }
        },

        {
            field: 'action',
            headerName: '',
            flex: 1,
            type: 'actions',
            getActions: ({ row }) => {
                return [
                    <Button variant="outlined" onClick={() => handleClickOpen(row)}>
                        {t('Edit')}
                    </Button>
                ];
            }
        }
    ]);

    return (
        <>
            {IsActive ? (
                <SkeletonEarningCard />
            ) : (
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography variant="h3">{t('Blog Categories')}</Typography>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        {' '}
                                        <DataGrid
                                            disableRowSelectionOnClick
                                            getRowHeight={() => 'auto'}
                                                      getEstimatedRowHeight={() => 200}
                                            rows={rows}
                                            getRowId={(row) => row._id}
                                            columns={columns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: { page: 0, pageSize: 10 }
                                                }
                                            }}
                                            pageSizeOptions={[5, 10]}
                                            sx={{
                                                border: 0,
                                                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                                                    outline: 'none !important'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography variant="h3">{t('Add Category')}</Typography>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Formik
                                            initialValues={{
                                                title_en: '',
                                                title_ar: ''
                                            }}
                                            validationSchema={Yup.object().shape({
                                                title_en: Yup.string().max(255).required(t('Title in English is required')),
                                                title_ar: Yup.string().max(255).required(t('Title in Arabic is required'))
                                            })}
                                            onSubmit={(values) => {
                                                createBlogCategory(values)
                                                    .then((res) => {
                                                        const response = res.data;
                                                        if(response.code === 1){
                                                            values.title_en = '';
                                                            values.title_ar = '';
                                                            toast.success(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                                                            setrows(response.data);
                                                        }
                                                        else{
                                                            toast.error(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                                                            setrows(response.data);
                                                        }
                                                    })
                                                    .catch((err) => {
                                                        if (err?.response?.status === 401) {
                                                            toast.error(err.response?.data?.error, toastOptions);
                                                        }
                                                    });
                                            }}
                                        >
                                            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                                <form noValidate onSubmit={handleSubmit}>
                                                    <FormControl
                                                        fullWidth
                                                        error={Boolean(touched.title_en && errors.title_en)}
                                                        sx={{ mb: 2 }}
                                                    >
                                                        <InputLabel htmlFor="outlined-adornment-email-login">{t('Title in English')}</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-email-login"
                                                            type="text"
                                                            value={values.title_en}
                                                            name="title_en"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            label="Title in English"
                                                        />
                                                        {touched.title_en && errors.title_en && (
                                                            <FormHelperText error id="standard-weight-helper-text-title_en-login">
                                                                {errors.title_en}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>
                                                    <FormControl fullWidth error={Boolean(touched.title_ar && errors.title_ar)}>
                                                        <InputLabel htmlFor="outlined-adornment-email-login">{t('Title in Arabic')}</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-email-login"
                                                            type="text"
                                                            value={values.title_ar}
                                                            name="title_ar"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            label="Title in Arabic"
                                                        />
                                                        {touched.title_ar && errors.title_ar && (
                                                            <FormHelperText error id="standard-weight-helper-text-title_ar-login">
                                                                {errors.title_ar}
                                                            </FormHelperText>
                                                        )}
                                                    </FormControl>

                                                    <Box sx={{ mt: 2 }}>
                                                        <Button fullWidth size="large" type="submit" variant="contained" color="secondary">
                                                            {t('Add')}
                                                        </Button>
                                                    </Box>
                                                </form>
                                            )}
                                        </Formik>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <Formik
                    initialValues={{
                        category_id: editData?._id,
                        title_en: editData?.title_en,
                        title_ar: editData?.title_ar,
                        status: editData?.status
                    }}
                    validationSchema={Yup.object().shape({
                        title_en: Yup.string().max(255).required(t('Title in English is required')),
                        title_ar: Yup.string().max(255).required(t('Title in Arabic is required'))
                    })}
                    onSubmit={(values) => {
                        updateBlogCategory(values)
                            .then((res) => {
                                const response = res.data;
                                if(response.code === 1){
                                    values.title_en = '';
                                    values.title_ar = '';
                                    values.status = '';
                                    toast.success(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                                    setrows(response.data);
                                    handleClose();
                                }
                                else{
                                    toast.error(i18n.language==='ar'?response.ar_message:response.en_message, toastOptions);
                                    setrows(response.data);
                                }
                            })
                            .catch((err) => {
                                if (err?.response?.status === 401) {
                                    toast.error(err.response?.data?.error, toastOptions);
                                }
                            });
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <>
                                <DialogContent>
                                    <Grid container spacing={2}>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Typography variant="h3" sx={{ mb: 2 }}>
                                                {t('Update Units')}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <FormControl fullWidth error={Boolean(touched.title_en && errors.title_en)} sx={{ mb: 2 }}>
                                                <InputLabel htmlFor="outlined-adornment-email-login">{t('Title in English')}</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-email-login"
                                                    type="text"
                                                    value={values.title_en}
                                                    name="title_en"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="Title in English"
                                                />
                                                {touched.title_en && errors.title_en && (
                                                    <FormHelperText error id="standard-weight-helper-text-title_en-login">
                                                        {errors.title_en}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <FormControl fullWidth error={Boolean(touched.title_ar && errors.title_ar)}>
                                                <InputLabel htmlFor="outlined-adornment-email-login">{t('Title in Arabic')}</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-email-login"
                                                    type="text"
                                                    value={values.title_ar}
                                                    name="title_ar"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    label="TItle in Arabic"
                                                />
                                                {touched.title_ar && errors.title_ar && (
                                                    <FormHelperText error id="standard-weight-helper-text-title_ar-login">
                                                        {errors.title_ar}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item lg={12} md={12} sm={12}>
                                            <Typography sx={{ mb: 1 }}>{t('Status')}</Typography>
                                            <FormControl fullWidth>
                                                <Select name="status" value={values.status} onChange={handleChange}>
                                                    <MenuItem value={true}>{t('Active')}</MenuItem>
                                                    <MenuItem value={false}>{t('Disable')}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button type="submit" variant="contained">
                                        {t('Update')}
                                    </Button>
                                </DialogActions>
                            </>
                        </form>
                    )}
                </Formik>
            </Dialog>
        </>
    );
};

export default BlogCategory;
