import {initializeApp}  from 'firebase/app'
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDCrXKWA9rXaAJ0GIwdnMHRCjS-VJvoNoc",
    authDomain: "omram-communication.firebaseapp.com",
    projectId: "omram-communication",
    storageBucket: "omram-communication.appspot.com",
    messagingSenderId: "210100008579",
    appId: "1:210100008579:web:e591fc2d5718d0c18f0db6",
    measurementId: "G-NM3DTTQP40"
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging();

// onMessage(messaging, (payload) => {
//     console.log('Message received. ', payload);
//     // ...
// });
// getToken(messaging, { vapidKey: 'BJihokRvN8cPE87MYeR1DLX6deBAatfoMcyKas0r9YdlAnnejl4IXnmEIk9VebV0NDDaywE_qsAR_4Ys0j3ELos' }).then((currentToken) => {
//     if (currentToken) {
//         localStorage.setItem('fcm',currentToken)
//         // ...
//     } else {
//         // Show permission request UI
//         // ...
//     }
// }).catch((err) => {
//     console.log('An error occurred while retrieving token. ', err);
//     // ...
// });

export const getTokens = async (setTokenFound) => {
//VAPID_KEY is the Web push certificates key pair
    return getToken(messaging, {vapidKey: 'BJAZpkBvf10K-pa4QL7FhWtMhFOulaMCkRVPyPog5TgsdxqpFl2ASl_SJdOdIyiMQiuP2sDoalbUEccekbu_00A' }).then((currentToken) => {
        if (currentToken) {
            setTokenFound(true);
            localStorage.setItem('fcm',currentToken)
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            setTokenFound(false);
            // shows on the UI that permission is required
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
});