import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import chatReducer from "./reducers/chatReducer";
import notificationReducer from "./reducers/notificationReducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    chat: chatReducer,
    customization: customizationReducer,
    notification: notificationReducer
});

export default reducer;
