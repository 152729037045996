import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {Avatar, Box, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery} from '@mui/material';

// project imports
import { MENU_OPEN, SET_MENU } from 'store/actions';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {useTranslation} from "react-i18next";
import Badge from "@mui/material/Badge";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level, notification }) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const Icon = item.icon;
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} size="1.3rem" />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />)
    };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id });
        if (matchesSM) dispatch({ type: SET_MENU, opened: false });
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                borderRadius: `${customization.borderRadius}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
                pl: `${level * 24}px`
            }}
            selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
            onClick={() => itemHandler(item.id)}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
            <ListItemText
                primary={
                    <Box sx={{display: 'flex', alignItems:'center'}}>
                        <Typography variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} color="inherit"
                            style={{marginRight: 2}}>
                            {i18n.language==='ar'?item.title_ar:item.title_en}
                        </Typography>
                        {
                            ((item.id==='Inquiries' && notification?.inquiryCount > 0) || (item.id==='Quotations' && notification?.quoteCount > 0)
                                || (item.id==='Advertise' && notification?.advertiseCount > 0)
                                || (item.id==='Products' && notification?.productsCount > 0)
                                || (item.id==='Companies' && notification?.companyCount > 0)
                                || (item.id==='Customers' && notification?.customerCount > 0)
                                || (item.id==='company-services' && notification?.compServiceCount > 0)
                                || (item.id==='company-projects' && notification?.compProjectCount > 0)
                                || (item.id==='Orders' && (notification?.orderCount + notification?.homeRepairRequestCount) > 0)
                                || (item.id==='individuals' && notification?.individualCount > 0)
                                || (item.id==='newsletter' && notification?.newsLetterCount > 0)
                                || (item.id==='Projects' && notification?.projectCount > 0)
                                || (item.id==='ProjectTransactions' && notification?.projectTransactionCount > 0)
                                || (item.id==='CategoryRequest' && notification?.catRequestCount > 0)
                                || (item.id==='technical-support' && notification?.wbTechnicalSupportCount > 0)
                                || (item.id==='payment-support' && notification?.wbPayInvSupportCount > 0)
                                || (item.id==='order-support' && notification?.wbOrderSupportCount > 0)
                                || (item.id==='general-support' && notification?.wbGeneralSupportCount > 0)
                                || (item.id==='wb-feedback' && notification?.feedbackCount > 0)
                                || (item.id==='company-bank' && notification?.bankCount > 0)
                                || (item.id==='app-support' && notification?.appSupportCount > 0)
                                || (item.id==='carts' && notification?.guestCartCount > 0)
                                || (item.id==='contact' && notification?.contactCount > 0)
                                || (item.id==='carts' && notification?.userCartCount > 0)
                            ) && (
                                <Badge  badgeContent={
                                    item.id==='Quotations'?notification?.quoteCount:item.id==='Inquiries'?notification?.inquiryCount:
                                        item.id==='Advertise'?notification?.advertiseCount:item.id==='Products'?notification?.productsCount:
                                        item.id==='Companies'?notification?.companyCount:item.id==='Customers'?notification?.customerCount:
                                        item.id==='company-services'?notification?.compServiceCount:item.id==='company-projects'?notification?.compProjectCount:
                                        item.id==='newsletter'?notification?.newsLetterCount:item.id==='Orders'?(notification?.orderCount + notification?.homeRepairRequestCount):
                                        item.id==='individuals'?notification?.individualCount:
                                        item.id==='Projects'?notification?.projectCount:
                                        item.id==='ProjectTransactions'?notification?.projectTransactionCount:
                                        item.id==='general-support'?notification?.wbGeneralSupportCount:
                                        item.id==='order-support'?notification?.wbOrderSupportCount:
                                        item.id==='payment-support'?notification?.wbPayInvSupportCount:
                                        item.id==='technical-support'?notification?.wbTechnicalSupportCount:
                                        item.id==='wb-feedback'?notification?.feedbackCount:
                                        item.id==='company-bank'?notification?.bankCount:
                                        item.id==='app-support'?notification?.appSupportCount:
                                        item.id==='contact'?notification?.contactCount:
                                        item.id==='carts'?(notification?.userCartCount + notification?.guestCartCount) :
                                            notification?.catRequestCount
                                }
                                       color="error" sx={{px: 1}}>

                                </Badge>
                            )
                        }
                    </Box>
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
