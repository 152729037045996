import React, { useState, useContext, useEffect } from 'react';
import {
    OutlinedInput,
    Chip,
    Grid,
    Typography,
    Button,
    Divider,
    TextField,
    InputAdornment,
    FormHelperText,
    MenuItem,
    FormControlLabel,
    Switch,
    IconButton, Box,
} from '@mui/material';

import { gridSpacing } from 'store/constant';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useNavigate, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DropZone from 'ui-component/DropZone';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import ToastContext from 'context/ToastContext';
import * as Yup from 'yup';
import {Field, FieldArray, Formik} from 'formik';

import AWS from 'aws-sdk';
import SkeletonEarningCard from 'ui-component/cards/Skeleton/EarningCard';
import {
    getServiceById,
    getProjectById, updateProject, getalladminAppSetting
} from 'api/api';
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import NewMap from "../NewMaps";
import file_thumbg from 'assets/images/file-thumb.png'
import imageCompression from "browser-image-compression";
import moment from "moment";



const EditProject = () => {
    const { t, i18n } = useTranslation();
    let currentUser = JSON.parse(localStorage.getItem('auth_user')).user;
    const service_id = localStorage.getItem('serv-id');
    const project_id = localStorage.getItem('project-id');
    const toastOptions = useContext(ToastContext);
    let navigate = useNavigate();
    let service = useParams();
    const [ProductImage, setProductImage] = useState();
    const [Service, setService] = useState([]);
    const [ButtonLoading, setButtonLoading] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [project, setProject] = useState();
    const [Images, setImages] = useState([]);
    const [Document, setDocument] = useState();
    const emptyMediaList_arr = { url: ''};
    const [settings, setSettings] = useState();

    useEffect(() => {
        getServiceById({ service_id: service_id }).then((res) => {
            setService(res.data.data);
        });
        getProjectById({ project_id: project_id }).then((res) => {
            const response = res.data;
            if(response.code===1){
                setProject(res.data.data);
                setImages(response.data.media);
                setDocument(response.data.project_document);
                setLoading(false);
            }
            else{
                setLoading(false);
                toast.error(i18n.language==='ar'?response.ar_message:response.en_message);
            }
        });
        getalladminAppSetting().then((res) => {
            setSettings(res.data.data);
        });
    }, []);

    const [uploadLink, setuploadLink] = useState([]);
    const [documentLink, setDocumentLink] = useState();

    const [LocationData, setLocationData] = useState('');
    const handleFileUpload = async (files) => {
        try {
            AWS.config.update({
                accessKeyId: settings.aws_access_key,
                secretAccessKey: settings.aws_secret_key,
                region: settings.aws_region
            });

            const s3 = new AWS.S3();
            const uploadPromises = [];

            for (let file of files) {

                const options = {
                    maxSizeMB: 1,
                }
                file = await imageCompression(file, options);
                let extension = file.name.split('.').pop();

                const params = {
                    ACL: 'public-read',
                    Bucket: settings.aws_bucket_name,
                    Key: `omran/company_project/${Date.parse(new Date())}.${extension}`,
                    Body: file
                };

                const uploadPromise = s3.upload(params).promise();
                uploadPromises.push(uploadPromise);
            }

            const responses = await Promise.all(uploadPromises);
            const uploadLinks = responses.map((response) => response.Location);
            setuploadLink(uploadLinks);
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };
    const handleDocumentUpload = async (file) => {
        try {
            const uploadPromises = [];

            const s3 = new AWS.S3();
            let extension = file[0].name.split('.').pop();
            const params = {
                ACL: 'public-read',
                Bucket: settings.aws_bucket_name,
                Key: `omran/company_project/${Date.parse(new Date())}.${extension}`,
                Body: file[0]
            };

            const response = await s3.upload(params).promise();
            setDocumentLink(response.Location);


        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    const handleImageDelete = (e, index) => {
        try {
            const newArr = Images.filter((object,i) => {
                return i !== index;
            });
            setImages(newArr)
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12}>
                {isLoading ? (
                    <SkeletonEarningCard />
                ) : (
                    <Card sx={{ minWidth: 250, cursor: 'pointer' }} elevation={2}>
                        <CardContent>
                            <Grid container spacing={gridSpacing}>
                                <Grid item lg={12}>
                                    <Box sx={{display:'flex', justifyContent: 'space-between'}}>
                                        <Box>
                                            <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>{t('Projects')}</Typography>
                                        </Box>
                                        <Box>
                                            <Button
                                                onClick={() => navigate(-1)}
                                                variant="contained"
                                                color="secondary"
                                                sx={{
                                                    mt: 0.7,
                                                }}
                                            >
                                                {t('Back')}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
                {isLoading ? (
                    <SkeletonEarningCard />
                ) : (
                    <Formik
                        initialValues={{
                            name_en: project.name_en,
                            name_ar: project.name_ar,
                            desc_en: project.desc_en,
                            desc_ar: project.desc_ar,

                            service_id: service_id,
                            user_id: project?.user_id,
                            company_id: project?.company_id,
                            media: project.media,
                            media_links: project.media_links && project.media_links.length ===0 ?[emptyMediaList_arr]:project.media_links,
                            project_document: project.project_document,
                            url1: '',
                            url2: '',
                            url3: '',
                            location:project.location,
                            client:project.client,
                            market_sector:project.market_sector,
                            architect:project.architect,
                            size:project.size,
                            type:project.type,
                            duration:project.duration,
                            completion_date:project.completion_date,
                            project_id:project._id,
                            app_platform: process.env.REACT_APP_PLATFORM,
                            logs: {
                                user: currentUser,
                                time: moment().format(),
                                lat: localStorage.getItem('lat'),
                                lng: localStorage.getItem('lng'),
                            }
                        }}
                        validationSchema={
                            Yup.object().shape({
                                name_en: Yup.string().max(255).required(t('Name in English is required')),
                                name_ar: Yup.string().max(255).required(t('Name Arabic is required')),
                                desc_en: Yup.string().max(255).required(t('Description English is required')),
                                desc_ar: Yup.string().max(255).required(t('Description Arabic is required')),
                            })
                        }
                        onSubmit={(values) => {
                            values.project_document = documentLink?documentLink:Document;

                            values.media = uploadLink.map((e) => ({ id: Math.random() * (99999 - 1), img: e }));
                            values.media = [...Images, ...values.media]
                            // if(values.url1!=='')
                            //     values.media_links = [{ url: values.url1 }, { url: values.url2 }, { url: values.url3 }];

                            if(LocationData!=='') {
                                values.location = {
                                    value: {
                                        description:LocationData.address,
                                        structured_formatting: {
                                            main_text: LocationData.city,
                                        }
                                    },
                                    lat: LocationData.lat,
                                    lng: LocationData.lng
                                };
                            }
                            if (values.media.length <= 0) {
                                toast.error('Image is required', toastOptions);
                            }
                            else {
                                setButtonLoading(true);
                                updateProject(values)
                                    .then((res) => {
                                        const response = res.data;
                                        if(response.code===1){
                                            toast.success(response.en_message, toastOptions);
                                            setButtonLoading(false);
                                            localStorage.removeItem('project-id');
                                            localStorage.removeItem('serv-id');
                                            navigate(`/company-projects`);
                                        }
                                        else{
                                            setButtonLoading(false);
                                            toast.error(response.en_message, toastOptions);
                                        }
                                    })
                                    .catch((err) => {
                                        if (err?.response?.status === 401) {
                                            setButtonLoading(false);
                                            toast.error(err.response?.data?.error, toastOptions);
                                        }
                                    });
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
                            <form noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Card sx={{ minWidth: 250 }}>
                                            <CardContent>
                                                <Grid container spacing={gridSpacing}>
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12}>
                                                                <Typography variant="h4">{t('Title')}</Typography>
                                                                <Divider sx={{ mt: 2 }} />
                                                            </Grid>
                                                            <Grid item lg={6} md={6}>
                                                                <Typography sx={{ mb: 1 }}>{t('Name English')}</Typography>
                                                                <TextField
                                                                    placeholder={t('Name English')}
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="name_en"
                                                                    value={values.name_en}
                                                                    error={Boolean(touched.name_en && errors.name_en)}
                                                                />
                                                                {touched.name_en && errors.name_en && (
                                                                    <FormHelperText error id="standard-weight-helper-text-name_en-login">
                                                                        {errors.name_en}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={6} md={6}>
                                                                <Typography sx={{ mb: 1 }}>{t('Name Arabic')}</Typography>
                                                                <TextField
                                                                    placeholder={t('Name Arabic')}
                                                                    fullWidth
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="name_ar"
                                                                    value={values.name_ar}
                                                                    error={Boolean(touched.name_ar && errors.name_ar)}
                                                                />
                                                                {touched.name_ar && errors.name_ar && (
                                                                    <FormHelperText error id="standard-weight-helper-text-name_ar-login">
                                                                        {errors.name_ar}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12}>
                                                                <Typography variant="h4">{t('Description')}</Typography>
                                                                <Divider sx={{ mt: 2 }} />
                                                            </Grid>
                                                            <Grid item lg={6} md={6}>
                                                                <Typography sx={{ mb: 1 }}>{t('Description English')}</Typography>
                                                                {
                                                                    i18n.language==='ar'?(
                                                                        <CKEditor
                                                                            id="ar-lang"
                                                                            config={{language:'ar'}}
                                                                            editor={ClassicEditor}
                                                                            data={project.desc_en}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                console.log(data);
                                                                                setFieldValue('desc_en', data);
                                                                            }}
                                                                        />
                                                                    ):(
                                                                        <CKEditor
                                                                            id="en-lang"
                                                                            config={{language:'en'}}
                                                                            editor={ClassicEditor}
                                                                            data={project.desc_en}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                console.log(data);
                                                                                setFieldValue('desc_en', data);
                                                                            }}
                                                                        />
                                                                    )
                                                                }
                                                                {touched.desc_en && errors.desc_en && (
                                                                    <FormHelperText error id="standard-weight-helper-text-desc_en-login">
                                                                        {errors.desc_en}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                            <Grid item lg={6} md={6}>
                                                                <Typography sx={{ mb: 1 }}>{t('Description Arabic')}</Typography>
                                                                {
                                                                    i18n.language==='ar'?(
                                                                        <CKEditor
                                                                            id="ar-lang"
                                                                            config={{language:'ar'}}
                                                                            editor={ClassicEditor}
                                                                            data={project.desc_ar}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                console.log(data);
                                                                                setFieldValue('desc_ar', data);
                                                                            }}
                                                                        />
                                                                    ):(
                                                                        <CKEditor
                                                                            id="en-lang"
                                                                            config={{language:'en'}}
                                                                            editor={ClassicEditor}
                                                                            data={project.desc_ar}
                                                                            onChange={(event, editor) => {
                                                                                const data = editor.getData();
                                                                                console.log(data);
                                                                                setFieldValue('desc_ar', data);
                                                                            }}
                                                                        />
                                                                    )
                                                                }
                                                                {touched.desc_ar && errors.desc_ar && (
                                                                    <FormHelperText error id="standard-weight-helper-text-desc_ar-login">
                                                                        {errors.desc_ar}
                                                                    </FormHelperText>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12}>
                                                                <Typography variant="h4">{t('Project Image')}</Typography>
                                                                <Divider sx={{ mt: 2, mb: 2 }} />
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                <DropZone onChange={handleFileUpload} limit={5} />
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{m: 2}}>
                                                                <Grid container spacing={2}>
                                                                    {
                                                                        Images.map(function(e,i) {
                                                                            return (

                                                                                <Grid item lg={3} md={6} sm={12} style={{position: 'relative', display:'flex'}}>
                                                                                    <IconButton
                                                                                        onClick={(e) => handleImageDelete(e,i)}
                                                                                        style={{top:'-10px', left: i18n.language==='ar'?'unset':'-10px', right: i18n.language==='ar'?'-10px':'unset',position: 'absolute', color: 'red'}} aria-label="delete"  color="danger">
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                    <img src={e.img} width={'80px'}/>
                                                                                </Grid>
                                                                            );
                                                                        })
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12}>
                                                                <Typography variant="h4">{t('Location')}</Typography>
                                                                <Divider sx={{ mt: 2 }} />
                                                            </Grid>

                                                            <Grid item lg={12} md={6} sm={12}>
                                                                <Typography sx={{ mb: 1 }}>{t('Location')}</Typography>
                                                                <NewMap oldAddress={project.location.value.description} center={{lat: project.location.lat, lng: project.location.lng}} zoom={15} onChange={setLocationData} />
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6}>
                                        <Card sx={{ minWidth: 250 }}>
                                            <CardContent>
                                                <Grid container spacing={gridSpacing}>
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12} sm={12}>
                                                                <Typography variant="h4">{t('Document')}</Typography>
                                                                <Divider sx={{ mt: 2 }} />
                                                            </Grid>
                                                            <Grid item lg={12} md={12} sm={12}>
                                                                <Typography sx={{ mb: 1 }}>{t('Upload Document')}</Typography>
                                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                    <DropZone onChange={handleDocumentUpload} limit={5} isFile={true} />
                                                                </Grid>
                                                                <Grid item lg={12} md={12} sm={12} xs={12} sx={{m: 2}}>
                                                                    <Grid container spacing={2}>
                                                                        {
                                                                            Document?( <Grid item lg={3} md={6} sm={12} style={{position: 'relative', display:'flex'}}>
                                                                                    <IconButton
                                                                                        onClick={(e) => {
                                                                                            setDocumentLink('')
                                                                                            setDocument('')
                                                                                        }}
                                                                                        style={{top:'-10px', left: i18n.language==='ar'?'unset':'-10px', right: i18n.language==='ar'?'-10px':'unset',position: 'absolute', color: 'red'}} aria-label="delete"  color="danger">
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                    <img src={file_thumbg} width={'80px'}/>
                                                                                </Grid>
                                                                            ):(
                                                                                <></>
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item lg={12} md={12} sm={12}>
                                                                <Typography variant="h4">{t('Specifications')}</Typography>
                                                                <Divider sx={{ mt: 2 }} />
                                                            </Grid>
                                                            <Grid item lg={3} md={6} sm={12}>
                                                                <Typography sx={{ mb: 1 }}>{t('Client')}</Typography>
                                                                <TextField
                                                                    placeholder={t('Client')}
                                                                    fullWidth
                                                                    type="text"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="client"
                                                                    value={values.client}
                                                                />
                                                            </Grid>
                                                            <Grid item lg={3} md={6} sm={12}>
                                                                <Typography sx={{ mb: 1 }}>{t('Duration')}</Typography>
                                                                <TextField
                                                                    placeholder={t('Duration')}
                                                                    fullWidth
                                                                    type="text"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="duration"
                                                                    value={values.duration}
                                                                />
                                                            </Grid>
                                                            <Grid item lg={3} md={6} sm={12}>
                                                                <Typography sx={{ mb: 1 }}>{t('Completion Date')}</Typography>
                                                                <TextField
                                                                    placeholder={t('Completion Date')}
                                                                    fullWidth
                                                                    type="date"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="completion_date"
                                                                    value={values.completion_date}
                                                                />
                                                            </Grid>
                                                            <Grid item lg={3} md={6} sm={12}>
                                                                <Typography sx={{ mb: 1 }}>{t('Size (SF)')}</Typography>
                                                                <TextField
                                                                    placeholder={t('Size (SF)')}
                                                                    fullWidth
                                                                    type="number"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="size"
                                                                    value={values.size}
                                                                />
                                                            </Grid>
                                                            <Grid item lg={3} md={6} sm={12}>
                                                                <Typography sx={{ mb: 1 }}>{t('Type')}</Typography>
                                                                <TextField
                                                                    placeholder={t('Type')}
                                                                    fullWidth
                                                                    type="text"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="type"
                                                                    value={values.type}
                                                                />
                                                            </Grid>
                                                            <Grid item lg={3} md={6} sm={12}>
                                                                <Typography sx={{ mb: 1 }}>{t('Architect')}</Typography>
                                                                <TextField
                                                                    placeholder={t('Architect')}
                                                                    fullWidth
                                                                    type="text"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="architect"
                                                                    value={values.architect}
                                                                />
                                                            </Grid>
                                                            <Grid item lg={3} md={6} sm={12}>
                                                                <Typography sx={{ mb: 1 }}>{t('Market Sector')}</Typography>
                                                                <TextField
                                                                    placeholder={t('Market Sector')}
                                                                    fullWidth
                                                                    type="text"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    name="market_sector"
                                                                    value={values.market_sector}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <FieldArray name="media_links">
                                                        {({ push, remove }) => (
                                                            <React.Fragment>
                                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                    <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
                                                                        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>{t('Media')}</Typography>
                                                                        {
                                                                            values.media_links.length<5?(
                                                                                <Button size="small"
                                                                                        onClick={() => push(emptyMediaList_arr)}
                                                                                        sx={{
                                                                                            backgroundColor: '#0e3152',
                                                                                            color: 'white',
                                                                                            mt: 0.7,
                                                                                            '&:hover': {
                                                                                                backgroundColor: '#7b8ea0'
                                                                                            }
                                                                                        }}
                                                                                >
                                                                                    {t('Add Media')}
                                                                                </Button>
                                                                            ):(
                                                                                <></>
                                                                            )
                                                                        }
                                                                    </Box>
                                                                </Grid>

                                                                {values.media_links.map((_, index) => (
                                                                    <Grid
                                                                        container
                                                                        item
                                                                        key={index}
                                                                        spacing={2}
                                                                    >
                                                                        <Grid item lg={12} md={12} xs={12}>
                                                                            <Typography sx={{ mb: 1 }}>{t('Video URL')} {index+1}</Typography>
                                                                            <div class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root muiltr-wb57ya-MuiFormControl-root-MuiTextField-root">
                                                                                <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary Mui-error MuiInputBase-fullWidth MuiInputBase-formControl muiltr-8skbml-MuiInputBase-root-MuiOutlinedInput-root">
                                                                                    <Field style={{border: '1px solid #bfc0c2'}}
                                                                                           placeholder={t('Video URL')+' '+(index+1)}
                                                                                           fullWidth
                                                                                           class="MuiInputBase-input MuiOutlinedInput-input muiltr-9lyone-MuiInputBase-input-MuiOutlinedInput-input"
                                                                                           name={`media_links.${index}.url`}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>

                                                    <Grid item lg={12} md={12} sm={12}>
                                                        <LoadingButton
                                                            type="submit"
                                                            variant="contained"
                                                            color="secondary"
                                                            loading={ButtonLoading}
                                                            loadingPosition="center"
                                                        >
                                                            {t('Update Project')}
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                )}
            </Grid>
        </Grid>
    );
};

export default EditProject;
