// action - customization reducer
import {getNotifications, getRequestMessage, sendRequestMessage} from "../api/api";
import { toast } from 'react-toastify';

export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

export const getChatList = (data, isLoading, i18n) => {
    return async (dispatch, getState) => {
        try {
            // Start loading
            if(isLoading){
                dispatch({ type: 'SET_CHAT_LOADING', payload: true });
            }

            const response = await getRequestMessage(data);
            if (response.data.code === 1) {
                dispatch({ type: 'SET_CHAT', payload: response.data.data });
            } else {
                // toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
            }
        } catch (error) {
            const response = error.response;
            toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
        } finally {
            // Stop loading
            if(isLoading){
                dispatch({ type: 'SET_CHAT_LOADING', payload: false });
            }
        }
    };
};

export const sendMessage = (data, isLoading, i18n) => {
    return async (dispatch, getState) => {
        try {
            // Start loading
            if(isLoading){
                dispatch({ type: 'SET_CHAT_LOADING', payload: true });
            }

            const response = await sendRequestMessage(data);
            if (response.data.code === 1) {
                dispatch({ type: 'SET_CHAT', payload: response.data.data });
            } else {
                // toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
            }
        } catch (error) {
            const response = error.response;
            toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
        } finally {
            // Stop loading
            if(isLoading){
                dispatch({ type: 'SET_CHAT_LOADING', payload: false });
            }
        }
    };
};

export const getAllNotification = (data, ) => {
    return async (dispatch, getState) => {
        try {

            const response = await getNotifications(data);
            if (response.data.code === 1) {
                dispatch({ type: 'SET_NOTIFICATION', payload: response.data.data });
            } else {
                // toast.error(i18n.language === 'ar' ? response.data.ar_message : response.data.en_message);
            }
        } catch (error) {
            const response = error.response;
        } finally {
            dispatch({ type: 'SET_NOTIFICATION_LOADING', payload: false });
        }
    };
};
